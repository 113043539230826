import AddCircleIcon from '@mui/icons-material/AddCircle';
import { GoalMenu } from '../../components/common/goal-menu';
import { GoalMenuItem } from '../../components/common/goal-menu-item';
import BusinessIcon from '@mui/icons-material/Business';
import InventoryIcon from '@mui/icons-material/Inventory';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GadminActions from '../../contexts/gadmin-actions';

export default function ShortcutPopover() {
  return (
    <GoalMenu startIcon={<AddCircleIcon color="primary" fontSize="large" />} iconOnly={true}>
      <GoalMenuItem
        label="Socio de negocio"
        to={`/busines-partners/new`}
        startIcon={<BusinessIcon />}
      />
      <GoalMenuItem label="Producto" to={`/inventory/products/new`} startIcon={<InventoryIcon />} />
      <GoalMenuItem
        label="Comprobantes"
        to={`/accounting`}
        startIcon={<DashboardIcon />}
        permission={GadminActions.GCC_ACCT_BALANCE}
      />
      <GoalMenuItem
        label="Admin"
        to={`/managment`}
        startIcon={<DashboardIcon />}
        permission={GadminActions.GCC_GPROC_MNGMT}
      />
    </GoalMenu>
  );
}
