import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography,
  Link
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

import { deliveryApi } from '../../../api/delivery';
import { useMounted } from '../../../hooks/use-mounted';
import { NumberColor } from '../../number-color';
import { useApp } from '../../../hooks/use-app';

export const HRInvoicingDocumentsTable = (props) => {
  const { id, type } = props;
  const mounted = useMounted();
  const { appUrlBase } = useApp();

  const [documentLogState, setDocumentLogState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });

  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentLogState(() => ({ ...documentLogState, isLoading: true }));
      try {
        const result = await deliveryApi.getPedidosHrPend({ id, type });
        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            data: result,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);

        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  if (!documentLogState.data) {
    return <LoadingContent loadingText="No hay documentos..." />;
  }
  const orderDetailRoute = `${appUrlBase}/sales/orders/`;
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={`Documentos de HR (${id})`} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              {/* <TableCell>#</TableCell> */}
              <TableCell># Tomador</TableCell>
              <TableCell># SAP</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Importe</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Término de pago</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentLogState.data?.map((item) => (
              <TableRow key={item.idpedido}>
                <TableCell>
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`${orderDetailRoute}${item.idpedido}`}
                    underline="none"
                    variant="subtitle2"
                  >
                    {item.idpedido}
                  </Link>
                </TableCell>
                <TableCell>{item.docnum}</TableCell>
                <TableCell>{moment(item.DocDate).format('DD/MM/YYYY')}</TableCell>
                {/* <TableCell>{item.Facturado}</TableCell> */}
                <TableCell sx={{ minWidth: 170 }}>
                  <Typography color="inherit" variant="body2">
                    {item.CardCode}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {item.CardName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <NumberColor value={item.DocTotal} minColor="success.main" format="0.00" />
                </TableCell>
                <TableCell>{item.DocStatus}</TableCell>
                <TableCell>{item.PymntGroup}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

HRInvoicingDocumentsTable.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(['pending', 'invoiced', 'all']).isRequired
};
