import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';

import { lazy } from 'react';
import { Loadable } from './loadable';

const Invoice = Loadable(
  lazy(() =>
    import('../containers/purchases/invoice').then((module) => ({
      default: module.Invoice
    }))
  )
);

const PromoAssigned = Loadable(
  lazy(() =>
    import('../containers/promo/promo-assigned').then((module) => ({
      default: module.PromoAssigned
    }))
  )
);

const Promo = Loadable(
  lazy(() =>
    import('../containers/promo/promo').then((module) => ({
      default: module.Promo
    }))
  )
);

export const PromoRoutes = [
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
        <Promo />
      </AllowedGuard>
    )
  },
  {
    path: 'assigneds',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <Promo />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <PromoAssigned />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: ':id',
    element: (
      <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
        <Invoice />
      </AllowedGuard>
    )
  }
];
