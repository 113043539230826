import {
  Alert,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import XMLViewer from 'react-xml-viewer';
import { Search } from '../../../../icons/search';
import useImageToBase64 from '../../../../hooks/use-image-to-base-64';
import { AllowedGuard } from '../../../../hooks/use-allowed';
import { Plus as PlusIcon } from '../../../../icons/plus';
import { useFormSaver } from '../../../../hooks/use-form-saver';
import { useFormChecker } from '../../../../hooks/use-form-checker';
import { goalCustomeDeleveryMonitoringApi } from '../../../../api/goal/monitoring/goal-customer-delivery-monitoring';
import GadminActions from '../../../../contexts/gadmin-actions';
import { Trash } from '../../../../icons/trash';
import { Pencil } from '../../../../icons/pencil';

const ExpenseForm = ({ update = false, initialValues = {}, title, onClose, handleRefresh, id }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileDocument, setFile] = useState(null);
  const [typeDocument, setDocument] = useState(true);
  const [fileDocumentXML, setDocumentXML] = useState('');

  const [dialogSaving, setDialogSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    base64Image: base64ImageFile,
    previewImage: previewImageFile,
    resetImage: resetImageFile
  } = useImageToBase64(selectedImage);
  const {
    base64Image: base64Document,
    previewImage: previewDocument,
    resetImage: resetDocument
  } = useImageToBase64(fileDocument);

  // console.log(previewDocument);

  // const handleDateChange = (date) => {
  //   formik.setFieldValue('created_at', date);
  // };

  const handleImagenChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    formik.setFieldValue('attacments', file);
  };

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    const { type } = file;

    if (type === 'application/pdf') {
      setDocument(true);
      setFile(file);
      formik.setFieldValue('pdf_id', file);
    } else if (type === 'text/xml' || type === 'application/xml') {
      setDocument(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        setDocumentXML(event.target.result);
        setFile(file);
        formik.setFieldValue('xml_id', file);
      };
      reader.readAsText(file);
      // setFile(event.target.result);
    } else {
      console.log('file not supported');
    }
    formik.setFieldValue('document', file);
  };

  const handleSearch = async () => {
    const resp = await goalCustomeDeleveryMonitoringApi.getCustomeDeliveryExpensesSuppliers(
      formik.values.licTradNum
    );
    if (resp.success) {
      const { id, name } = resp.data[0];
      formik.setFieldValue('cardName', name);
      formik.setFieldValue('cardCode', id);
    } else {
      setErrorMessage(resp.response.errorData.message);
      formik.setFieldValue('cardName', '');
      formik.setFieldValue('cardCode', '');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  const handleResetXML = () => {
    resetDocument();
    setDocumentXML('');
  };

  const handleAfterSave = () => {
    onClose(false);
    handleRefresh();
  };

  const [, handleAdd] = useFormSaver({
    originalData: initialValues,
    apiAddHandler: goalCustomeDeleveryMonitoringApi.postCustomeFormExpenses,
    handleAfterSave
  });

  const formik = useFormik({
    initialValues: {
      receiptType: initialValues.receiptType || '',
      description: initialValues.description || '',
      licTradNum: initialValues.licTradNum || '',
      cardName: initialValues.cardName || '',
      cardCode: initialValues.cardCode || '',
      created_at: initialValues.created_at || new Date().toString(),
      docTotal: initialValues.docTotal || 0,
      attacments: initialValues.attacments || null,
      pdf_id: initialValues.pdf_id || '',
      xml_id: initialValues.xml_id || '',
      document: initialValues.document || null,
      checked: initialValues.checked || false,
      dispatch_id: initialValues.dispatch_id || id
    },
    validationSchema: Yup.object().shape({
      receiptType: Yup.string().required('Este campo es obligatorio'),
      description: Yup.string().required('Este campo es obligatorio'),
      licTradNum: Yup.string().required('Este campo es obligatorio'),
      docTotal: Yup.number()
        .required('Este campo es obligatorio')
        .test('is-positive', 'El número debe ser mayor a cero', (value) => value > 0)
    }),
    onSubmit: async (values, helpers) => {
      // Lógica de envío de datos
      formik.values.attacments = base64ImageFile;
      if (formik.values.checked && typeDocument) {
        formik.values.pdf_id = base64Document;
      }
      if (formik.values.checked && !typeDocument) {
        formik.values.xml_id = base64Document;
      }
      // formik.values.document = base64Document;
      console.log(values);
      await handleAdd({ values, helpers });
    }
  });

  const [checkForm] = useFormChecker({ formik, setSaving: setDialogSaving });

  useEffect(() => {
    if (dialogSaving && !formik.isSubmitting) {
      checkForm();
      formik.submitForm();
      if (dialogSaving) setDialogSaving(false);
    }
  }, [dialogSaving]);

  useEffect(() => {
    setDialogSaving(formik.isSubmitting);
  }, [formik.isSubmitting]);

  return (
    <Box sx={{ minWidth: 'auto' }}>
      <form onSubmit={formik.handleSubmit} style={{ padding: '20px' }}>
        <DialogTitle>{title}</DialogTitle>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={6}>
            <TextField
              select
              label="Tipo de Gasto"
              name="receiptType"
              value={formik.values.receiptType}
              onChange={formik.handleChange}
              variant="outlined"
              fullWidth
              error={formik.touched.receiptType && Boolean(formik.errors.receiptType)}
              helperText={formik.touched.receiptType && formik.errors.receiptType}
            >
              <MenuItem value="opcion1">Combustible</MenuItem>
              <MenuItem value="opcion2">Viatico</MenuItem>
              <MenuItem value="opcion3">Peaje</MenuItem>
              <MenuItem value="opcion4">Almuerzo</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              label="Descriptición"
              variant="outlined"
              fullWidth
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Grid>
          {/* ------------ */}
          <Grid item xs={12}>
            <TextField
              placeholder="Ingrese RUC"
              name="licTradNum"
              value={formik.values.licTradNum}
              onChange={formik.handleChange}
              label="Proveedor"
              variant="outlined"
              fullWidth
              error={formik.touched.licTradNum && Boolean(formik.errors.licTradNum)}
              helperText={formik.touched.licTradNum && formik.errors.licTradNum}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                    <Search onClick={handleSearch} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cardName"
              value={formik.values.cardName}
              onChange={formik.handleChange}
              label="Nombre"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cardCode"
              value={formik.values.cardCode}
              onChange={formik.handleChange}
              label="Código"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="warning">{errorMessage}</Alert>
            </Grid>
          )}
          {/* <Grid item xs={6}>
            <InputField
              label="Fecha"
              name="created_at"
              value={formik.values.created_at}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TextField
              name="docTotal"
              type="number"
              value={formik.values.docTotal}
              onChange={formik.handleChange}
              label="Importe"
              variant="outlined"
              fullWidth
              error={formik.touched.docTotal && Boolean(formik.errors.docTotal)}
              helperText={formik.touched.docTotal && formik.errors.docTotal}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.checked}
                  onChange={formik.handleChange}
                  name="checked"
                  color="primary"
                />
              }
              label="Comprobante electrónico"
            />
          </Grid>

          {!formik.values.checked ? (
            <Grid item xs={12}>
              {!previewImageFile && (
                <Box
                  sx={{
                    width: 'auto',
                    minHeight: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#fff'
                  }}
                  style={{
                    background: '#131313'
                  }}
                >
                  {!previewImageFile && (
                    <>
                      <p>Agregar imagen</p>
                      <InputLabel
                        htmlFor="fileUpload"
                        style={{
                          color: '#55d6d6',
                          fontSize: '22px',
                          fontWeight: '500',
                          padding: '6px 12px',
                          cursor: 'pointer'
                        }}
                      >
                        Elejir el archivo
                      </InputLabel>
                      <input
                        accept="image/*"
                        type="file"
                        name="attacments"
                        id="fileUpload"
                        onChange={handleImagenChange}
                        style={{
                          color: 'transparent',
                          position: 'absolute',
                          margin: 0,
                          padding: 0,
                          width: '250px',
                          height: '250px',
                          outline: 'none',
                          opacity: 0,
                          cursor: 'pointer'
                        }}
                      />
                    </>
                  )}
                </Box>
              )}

              {previewImageFile && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Typography color="textSecondary" variant="body2">
                    {selectedImage.name}{' '}
                  </Typography>
                  <img
                    src={previewImageFile}
                    style={{
                      width: '80%',
                      height: '80%',
                      objectFit: 'contain'
                    }}
                    alt="Preview"
                  />
                </div>
              )}
              {previewImageFile && (
                <Button onClick={() => resetImageFile(null)}>Eliminar imagen</Button>
              )}
            </Grid>
          ) : (
            <Grid item xs={12}>
              {!previewDocument && (
                <Box
                  sx={{
                    width: 'auto',
                    minHeight: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#fff'
                  }}
                  style={{
                    background: '#131313',
                    display: fileDocumentXML !== '' ? 'none' : 'flex'
                  }}
                >
                  <p>Agregar documento</p>
                  <InputLabel
                    htmlFor="documentUpload"
                    style={{
                      color: '#55d6d6',
                      fontSize: '22px',
                      fontWeight: '500',
                      padding: '6px 12px',
                      cursor: 'pointer'
                    }}
                  >
                    Elejir el archivo
                  </InputLabel>
                  <input
                    type="file"
                    name="document"
                    accept=".pdf,.XML"
                    id="documentUpload"
                    onChange={handleDocumentChange}
                    style={{
                      color: 'transparent',
                      position: 'absolute',
                      margin: 0,
                      padding: 0,
                      width: '250px',
                      height: '250px',
                      outline: 'none',
                      opacity: 0,
                      cursor: 'pointer'
                    }}
                  />
                </Box>
              )}

              {typeDocument && previewDocument && (
                <embed src={previewDocument} width="100%" height="400" />
              )}
              {!typeDocument && fileDocumentXML !== '' && <XMLViewer xml={fileDocumentXML} />}
              {previewDocument && typeDocument && (
                <Button onClick={resetDocument}>Eliminar Documento pdf</Button>
              )}
              {fileDocumentXML !== '' && (
                <Button onClick={handleResetXML}>Eliminar Documento xml</Button>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <AllowedGuard permission={GadminActions.GCC_TDOY_EXPENSE_EDIT}>
              <LoadingButton
                color="primary"
                size="large"
                startIcon={update ? <Pencil /> : <PlusIcon />}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
              >
                {update ? 'Editar' : 'Guardar'}
              </LoadingButton>
              <Button
                color="secondary"
                size="large"
                startIcon={<Trash />}
                onClick={() => onClose(false)}
                sx={{
                  marginLeft: 1
                }}
              >
                Cerrar
              </Button>
            </AllowedGuard>
          </DialogActions>
        </Grid>
      </form>
    </Box>
  );
};

export default ExpenseForm;

ExpenseForm.propTypes = {
  update: PropTypes.bool,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  title: PropTypes.string,
  handleRefresh: PropTypes.func,
  id: PropTypes.string
  // dataModal: PropTypes.array
};
