import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
import { lazy } from 'react';
import { Loadable } from './loadable';

const Invoices = Loadable(
  lazy(() =>
    import('../containers/purchases/invoices').then((module) => ({
      default: module.Invoices
    }))
  )
);

const ReceiptNumber = Loadable(
  lazy(() =>
    import('../containers/sales/document/receipt-number').then((module) => ({
      default: module.ReceiptNumber
    }))
  )
);

const Receipt = Loadable(
  lazy(() =>
    import('../containers/sales/document/receipt').then((module) => ({
      default: module.Receipt
    }))
  )
);

const CustomerOrders = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-orders').then((module) => ({
      default: module.CustomerOrders
    }))
  )
);

const CustomerOrdersPending = Loadable(
  lazy(() =>
    import('../containers/sales/orders/pendings').then((module) => ({
      default: module.CustomerOrdersPending
    }))
  )
);

const CustomerOrdersAuthorized = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-orders-authorized').then((module) => ({
      default: module.CustomerOrdersAuthorized
    }))
  )
);

const CustomerUnauthorizedOrders = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-unauthorized-orders').then((module) => ({
      default: module.CustomerUnauthorizedOrders
    }))
  )
);

const CustomerOrdersCanceled = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-orders-canceled').then((module) => ({
      default: module.CustomerOrdersCanceled
    }))
  )
);

const CustomerOrdersOpenned = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-orders-openned').then((module) => ({
      default: module.CustomerOrdersOpenned
    }))
  )
);

const CustomerOrder = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-order').then((module) => ({
      default: module.CustomerOrder
    }))
  )
);

const CustomerOrderDraft = Loadable(
  lazy(() =>
    import('../containers/sales/orders/customer-order-draft').then((module) => ({
      default: module.CustomerOrderDraft
    }))
  )
);

const CustomerReturns = Loadable(
  lazy(() =>
    import('../containers/customer-returns/customer-returns').then((module) => ({
      default: module.CustomerReturns
    }))
  )
);

const CustomerReturnsReasons = Loadable(
  lazy(() =>
    import('../containers/customer-returns/customer-returns-reasons').then((module) => ({
      default: module.CustomerReturnsReasons
    }))
  )
);

const CustomerReturnsProducts = Loadable(
  lazy(() =>
    import('../containers/customer-returns/customer-returns-products').then((module) => ({
      default: module.CustomerReturnsProducts
    }))
  )
);

const CustomerReturn = Loadable(
  lazy(() =>
    import('../containers/customer-returns/customer-return').then((module) => ({
      default: module.CustomerReturn
    }))
  )
);

export const SalesRoutes = [
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
        <Invoices />
      </AllowedGuard>
    )
  },
  {
    path: 'receipts',
    children: [
      {
        path: 'number',
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_DET_ORDER_FAC}>
                <ReceiptNumber />
              </AllowedGuard>
            )
          },
          {
            path: ':id',
            element: (
              <AllowedGuard permission={GadminActions.GCC_DET_ORDER_FAC}>
                <ReceiptNumber />
              </AllowedGuard>
            )
          }
        ]
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_DET_ORDER_FAC}>
            <Receipt />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <CustomerOrders />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <CustomerOrdersPending />
          </AllowedGuard>
        )
      },
      {
        path: 'authorizeds',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <CustomerOrdersAuthorized />
          </AllowedGuard>
        )
      },
      {
        path: 'unauthorizeds',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_RET}>
            <CustomerUnauthorizedOrders />
          </AllowedGuard>
        )
      },
      {
        path: 'cancelleds',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <CustomerOrdersCanceled />
          </AllowedGuard>
        )
      },
      {
        path: 'openneds',
        element: (
          <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
            <CustomerOrdersOpenned />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
                <CustomerOrder />
              </AllowedGuard>
            )
          },
          {
            path: 'draft',
            element: (
              <AllowedGuard permission={GadminActions.GCC_BROWSE_ORDER_REG}>
                <CustomerOrderDraft />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'returns',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <CustomerReturns />
          </AllowedGuard>
        )
      },
      {
        path: 'reasons',
        element: (
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <CustomerReturnsReasons />
          </AllowedGuard>
        )
      },
      {
        path: 'products',
        element: (
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <CustomerReturnsProducts />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings',
        element: (
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <CustomerReturnsReasons />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_CD_LIST}>
            <CustomerReturn />
          </AllowedGuard>
        )
      }
    ]
  }
];
