import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './new-year.css'; // Asegúrate de crear este archivo CSS
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const NewYear = ({ year }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShowMessage(true);
    const timer = setInterval(() => {
      setShowMessage((prev) => !prev);
    }, 5000); // El mensaje aparece y desaparece cada 5 segundos
    return () => clearInterval(timer);
  }, []);

  //   const year = new Date().getFullYear();
  return (
    <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 9999 }}>
      <CSSTransition in={showMessage} timeout={500} classNames="fade" unmountOnExit>
        <Typography variant="h1" className="mensaje">
          🎉 ¡Feliz Año Nuevo{' '}
          <Box component="span" className="message-year">
            {year}
          </Box>
          ! 🎉
        </Typography>
      </CSSTransition>
    </Box>
  );
};
NewYear.propTypes = {
  year: PropTypes.number.isRequired
};

export default NewYear;
