import React from 'react';
import PropTypes from 'prop-types';
import { NumberColor } from '../../number-color';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { progressTypes } from './grid-definitions';

const ProgressContainer = styled('div')(({ progress, color }) => ({
  position: 'relative',
  width: '100px',
  height: '20px',
  backgroundColor: progress == 0 ? 'grey' : 'rgb(47, 49, 55)',
  borderRadius: '4px',
  overflow: 'hidden', // To prevent the background progress bar from overflowing the container
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    // Using pseudo-element for the progress background
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: `${progress}%`, // Dynamically set width for progress
    backgroundColor: color,
    zIndex: 1, // Place the progress bar behind text
    borderRadius: 'inherit'
  }
}));

// get discount percentage color
const getDiscountColor = (value) => {
  if (value < 0.1) {
    return '#5aac0d';
  }
  if (value < 0.2) {
    return '#e37b26';
  }
  return 'red';
};

const getProgressColor = (progress) => {
  if (progress < 70) return 'red';
  if (progress < 80) return 'orange';
  if (progress < 90) return 'green';
  if (progress < 95) return 'blue';
  return 'green';
};

const getMarginColor = (margin) => {
  if (margin < 4) return 'red';
  if (margin < 6) return 'orange';
  if (margin < 10) return 'grey';
  if (margin < 15) return 'green';
  if (margin < 25) return 'blue';
  return 'lightblue';
};

const getTaxColor = (tax) => {
  if (tax < 0.01) return 'grey';
  return 'orange';
};

const ProgressBar = ({ value: valueProp, format, variant = 'percent' }) => {
  const value = parseFloat(valueProp || 0);
  const color =
    variant === 'percent'
      ? getProgressColor(value)
      : variant === 'margin'
        ? getMarginColor(value)
        : variant === 'tax'
          ? getTaxColor(value)
          : getDiscountColor(value);
  return (
    <ProgressContainer progress={value * 100} color={color}>
      <Box
        component="span"
        sx={{
          color: 'white',
          display: 'block',
          fontSize: '0.9rem',
          zIndex: 2,
          // reduce font size for smaller progress bars
          '@media (max-width: 500px)': {
            fontSize: '0.8rem'
          }
        }}
      >
        <NumberColor value={value} format={format} withoutStyle />
      </Box>
    </ProgressContainer>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.string,
  variant: PropTypes.oneOf(progressTypes)
};
export { ProgressBar };
export default ProgressBar;
