import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { useApp } from '../../hooks/use-app';

import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { useQuery } from '../../hooks/use-query';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { goalCustomerPromotionsApi } from '../../api/goal/promotions/goal-customer-promotions';
import { SalesTeamsBrandsTable } from '../../components/sales-teams/sales-teams-brands-table';

export const SalesTeamsBrands = () => {
  const query = useQuery();

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title } = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    ,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalCustomerPromotionsApi.getPromotionsList,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all'
    }
  });

  // console.log(returnsState);

  const data = [
    {
      id: 1,
      cod: '001',
      name: 'MINORISTA',
      provider: 'PM00000018',
      provider_name: 'ILKO PERU S.A.C.',
      active: 'Y'
    },
    {
      id: 2,
      cod: '002',
      name: 'MAYORISTA',
      provider: 'PM00000027',
      provider_name: 'NESTLE PERU S.A.',
      active: 'Y'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title('Marcas')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Marcas
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.GCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
            <SalesTeamsBrandsTable
              error={returnsState.error}
              // documents={returnsState.documents}
              documents={data}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
            <Divider />
          </Card>
        </Container>
      </Box>
    </>
  );
};
