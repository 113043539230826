import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { permissionsApi } from '../../api/managment/permissions';
import { ConstantStatus } from '../../api/application-enums';
import { useData } from '../../hooks/use-data';

const PermissionsContext = createContext();

const usePermission = () => {
  return useContext(PermissionsContext);
};

const PermissionsProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  const [editMode, setEditMode] = useState(false);

  const [addRole, setAddRole] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: permissionsApi.getPermission,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando información del rol...',
    mounted
  });

  useEffect(() => {
    handleRefresh();
  }, [id]);

  const value = {
    view: view,
    loading: dataState.isLoading,
    permission: dataState.data ?? {},
    handleRefresh: handleRefresh,
    error: dataState.displayError ? dataState.displayError : false,
    message: dataState.message ?? '',
    editMode,
    setEditMode,
    addRole,
    setAddRole
  };
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

PermissionsProvider.propTypes = { children: PropTypes.node.isRequired };

export { PermissionsProvider, usePermission };
