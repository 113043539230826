import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel
} from '@mui/material';
import moment from 'moment';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { X as XIcon } from '../../../icons/x';
import { useApp } from '../../../hooks/use-app';
import { PropertyList } from '../../property-list';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { goalCustomeDeleveryPlanningApi } from '../../../api/goal/planning/goal-customer-delivery-planning';
import useFormikMultiSelect from '../../../hooks/use-formik-multiselect';
import MultiSelectField from '../../common/multi-select-field';
import { CommonDatePicker } from '../../common/common-date-picker';

export const DeliveryPlanningForm = ({
  update = false,
  title,
  initialValues = {},
  onClose,
  handleRefresh,
  view = 0
}) => {
  const { currentCompany, showNotify, showError } = useApp();
  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const [zonePlanning, setZonePlanning] = useState([]);
  const [zonePlanningData, setZonePlanningData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const [zonePlanning] = await Promise.all([
      goalCustomeDeleveryPlanningApi.getZonePlanning({ account: view })
    ]);

    if (zonePlanning.success && zonePlanning.data.length !== 0) {
      setZonePlanning(zonePlanning.data);
      setZonePlanningData(zonePlanning.data);
    }
  };

  const handleAfterSave = () => {
    onClose(false);
    handleRefresh();
  };

  const formik = useFormik({
    initialValues: {
      companyId: initialValues.companyId || currentCompany,
      zones: initialValues.zones || [],
      date: initialValues.date || moment().toDate(),
      account: initialValues.account || view
    },
    validationSchema: Yup.object().shape({
      date: Yup.date().required('La fecha es importante'),
      zones: Yup.array()
        .of(
          Yup.object().shape({
            code: Yup.string().required(),
            name: Yup.string().required()
          })
        )
        .min(1, 'Seleccione al menos una zona')
    }),
    onSubmit: async (values) => {
      const codes = values.zones.map((item) => item.code);
      const newValues = { ...values, zones: codes, date: moment(values.date).format('YYYY-MM-DD') };
      console.log('values', newValues);
      const response = await goalCustomeDeleveryPlanningApi.postPlanningForm({
        values: newValues
      });
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        handleAfterSave();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
  });

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const handleSelectAllChange = (e) => {
    const allData = zonePlanning.map((item) => item);
    const selectedOptions = e.target.checked ? allData : [];
    formik.setFieldValue('zones', selectedOptions);
    setSelectAll(e.target.checked);
  };

  return (
    <Box sx={{ minWidth: 'auto', display: 'flex', flex: 1, flexDirection: 'column', padding: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12} md={update ? 6 : 12}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              {title}
            </InputLabel>
            <PropertyList>
              <CompanyInputDisabled currentCompany={currentCompany} />
              {/* <InputField
                name="date"
                label="Fecha"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                sx={{ marginBottom: '1em' }}
                inputProps={{
                  min: moment().add(-3, 'day').format('YYYY-MM-DD'),
                  max: moment().format('YYYY-MM-DD')
                }}
              /> */}
              <CommonDatePicker
                formik={formik}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                dateName="date"
                minValue={moment().add(-10, 'day').toDate()}
                maxValue={moment().toDate()}
              />

              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: 1
                }}
              >
                <Grid item xs={9}>
                  <MultiSelectField
                    label="Zona"
                    name="zones"
                    value={zonePlanning.length === 0 ? [] : formik.values.zones}
                    onChange={(e) =>
                      handleMultiSelectChange(
                        e,
                        'zones',
                        setZonePlanningData,
                        true,
                        zonePlanningData
                      )
                    }
                    error={formik.touched.zones && Boolean(formik.errors.zones)}
                    options={zonePlanning}
                    getOptionName={(value) => getOptionsName(value.code, zonePlanning)}
                    onDeleteOption={(value) =>
                      handleOptionsDelete(
                        value,
                        'zones',
                        formik.values.zones,
                        setZonePlanningData,
                        true,
                        zonePlanningData
                      )
                    }
                    sx={{
                      width: '500px'
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    sx={{
                      marginTop: 3
                    }}
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        name="selectAll"
                        color="secondary"
                      />
                    }
                    label="Todos"
                  />
                </Grid>
              </Grid>

              {formik.values.zones && formik.errors.zones && (
                <FormHelperText error> Este campo no zona no debe estar vacio </FormHelperText>
              )}
            </PropertyList>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.GCC_TDOY_LIST}>
                <LoadingButton
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Cargar
                </LoadingButton>
                <Button
                  color="secondary"
                  size="large"
                  startIcon={<XIcon />}
                  onClick={() => onClose(false)}
                  sx={{
                    marginLeft: 1
                  }}
                >
                  Cerrar
                </Button>
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

DeliveryPlanningForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  title: Proptypes.string,
  handleRefresh: Proptypes.func,
  view: Proptypes.number
};
