import { dataLoader } from '../../../data-loader';

const getReceiptTypesWithChildren = async ({ companyId }) => {
  const result = await dataLoader.loadGoalGetData({
    action: `/base/common/receipt-types/with-children?companyId=${companyId}`
  });
  return result;
};

const syncReceiptTypes = async ({ companyId }) => {
  const result = await dataLoader.loadGoalPost({
    action: `/base/common/receipt-types/sync?companyId=${companyId}`
  });
  return result;
};

export { getReceiptTypesWithChildren, syncReceiptTypes };
