import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';
import { SirePurchasesProvider } from '../contexts/sunat/sire-purchases-context';
import { SireSalesProvider } from '../contexts/sunat/sire-sales-context';

const AccountingDashboard = Loadable(
  lazy(() =>
    import('../containers/accounting/accounting-dashboard').then((module) => ({
      default: module.AccountingDashboard
    }))
  )
);

const FEPendingsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-facele').then((module) => ({
      default: module.FEPendingsFacele
    }))
  )
);

const FEPendingsSAP = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-pendings-sap').then((module) => ({
      default: module.FEPendingsSAP
    }))
  )
);

const FESentsFacele = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/fe-sents-facele').then((module) => ({
      default: module.FESentsFacele
    }))
  )
);

const GREAll = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-all').then((module) => ({
      default: module.GREAll
    }))
  )
);

const GREPendings = Loadable(
  lazy(() =>
    import('../containers/accounting/fe/gre-pendings').then((module) => ({
      default: module.GREPendings
    }))
  )
);

const Journal = Loadable(
  lazy(() =>
    import('../containers/accounting/journal/journal').then((module) => ({
      default: module.Journal
    }))
  )
);

const KardexDiscrepancies = Loadable(
  lazy(() =>
    import('../containers/managment/kardex/karedex-discrepancies').then((module) => ({
      default: module.KardexDiscrepancies
    }))
  )
);

/**
 * (START) SUNAT MODULE
 */
const SireSales = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire/sire-sales').then((module) => ({
      default: module.SireSales
    }))
  )
);

// SireSalesRceSummary
// const SireSalesRceSummary = Loadable(
//   lazy(() =>
//     import('../containers/accounting/sunat/sire/sire-sales-rce-summary').then((module) => ({
//       default: module.SireSalesRceSummary
//     }))
//   )
// );

const SirePurchases = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire/sire-purchases').then((module) => ({
      default: module.SirePurchases
    }))
  )
);

const SirePurchasesRceSummaryTabs = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-summary-tabs').then(
      (module) => ({
        default: module.SireRceSummaryTabs
      })
    )
  )
);

const SirePurchasesRceSummary = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-summary').then((module) => ({
      default: module.SireRceSummary
    }))
  )
);

const SirePurchasesRceProposal = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-rce-proposal').then((module) => ({
      default: module.SireRceProposal
    }))
  )
);

const SirePurchasesInconsistencies = Loadable(
  lazy(() =>
    import('../containers/accounting/sunat/sire-purchases/sire-inconsistencies').then((module) => ({
      default: module.SireInconsistencies
    }))
  )
);

/**
 * (END) SUNAT MODULE
 */

export const AccountingRoutes = [
  {
    path: 'fe',
    children: [
      {
        path: 'sents-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FESentsFacele />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-sap',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsSAP />
          </AllowedGuard>
        )
      },
      {
        path: 'pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <FEPendingsFacele />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'gre',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREAll />
          </AllowedGuard>
        )
      },
      {
        path: 'gre-pendings-facele',
        element: (
          <AllowedGuard permission={GadminActions.GCC_FACELE_SEND}>
            <GREPendings />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'journals',
    children: [
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_ACCT_JOURNAL_VIEW}>
            <Journal />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: '',
    element: (
      <AllowedGuard permission={GadminActions.GCC_ACCT_PROCESS}>
        <AccountingDashboard />
      </AllowedGuard>
    )
  },
  {
    path: 'balance',
    element: (
      <AllowedGuard permission={GadminActions.G2_KARDEX_PROCESS}>
        <KardexDiscrepancies />
      </AllowedGuard>
    )
  },
  {
    path: 'sunat',
    children: [
      {
        path: 'sire-sales',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SireSalesProvider>
              <SireSales />
            </SireSalesProvider>
          </AllowedGuard>
        )
      },
      {
        path: 'sire-purchases',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <SirePurchasesProvider>
              <SirePurchases />
            </SirePurchasesProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: 'summary',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SirePurchasesRceSummaryTabs />
              </AllowedGuard>
            ),
            children: [
              {
                path: '',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesRceSummary />
                  </AllowedGuard>
                )
              },
              {
                path: 'cp-summary-excluded',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesInconsistencies />
                  </AllowedGuard>
                )
              },
              {
                path: 'cp-preliminary-rce-summary',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesInconsistencies />
                  </AllowedGuard>
                )
              },
              {
                path: 'rce-summary-generated',
                element: (
                  <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                    <SirePurchasesInconsistencies />
                  </AllowedGuard>
                )
              }
            ]
          },
          {
            path: 'rce-proposal',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SirePurchasesRceProposal />
              </AllowedGuard>
            )
          },
          {
            path: 'inconsistencies',
            element: (
              <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
                <SirePurchasesInconsistencies />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  }
];
