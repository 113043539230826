import { dataLoader } from '../../data-loader';

const getCurrentUserDetails = async () => {
  const response = await dataLoader.goalGet({
    action: `auth/users/current`,
    params: {
      withDetails: true,
      withPermissions: true
    }
  });
  return response;
};

export { getCurrentUserDetails };
