import { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import Scrollbar from '../../Scrollbar';
import { RichTreeView, TreeItem2, useTreeViewApiRef } from '@mui/x-tree-view';
import ClearIcon from '@mui/icons-material/Clear';
import { alpha, styled } from '@mui/material/styles';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { InputField } from '../../input-field';
import { DeleteButton } from '../buttons/delete-button';

const getItemId = (item) => {
  return `${item.id}`;
};
const getItemLabel = (item) => item.name;

const isItemDisabled = (item) => {
  return item.disabled ?? false;
};

const CustomTreeItem = styled(TreeItem2)(function ({ theme }) {
  return {
    color: theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      [`& .${treeItemClasses.label}`]: {
        fontSize: '0.8rem',
        fontWeight: 500
      }
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(0, 1.2),
      ...theme.applyStyles('light', {
        backgroundColor: alpha(theme.palette.primary.main, 0.25)
      }),
      ...theme.applyStyles('dark', {
        color: theme.palette.primary.contrastText
      })
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
    },
    ...theme.applyStyles('light', {
      color: theme.palette.grey[800]
    })
  };
});

CustomTreeItem.propTypes = {
  item: Proptypes.object
};

export const CommonRichTreeView = ({
  onSelectNode,
  onDeleteNode = () => {},
  onLevelChange = () => {},
  deleteAction = false,
  permission,
  items
}) => {
  const apiRef = useTreeViewApiRef();

  const [selectedNode, setSelectedNode] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [expandedItems, setExpandedItems] = useState([]);

  const findItemById = (items, itemId) => {
    for (const item of items) {
      if (item.id == itemId) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, itemId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    if (isSelected) {
      const selectedItem = findItemById(items, itemId);
      onSelectNode(selectedItem);
      setSelectedNode(selectedItem);
      onLevelChange?.(selectedItem.level);
    }
  };

  const handleClickDelete = async (idDelete) => {
    onDeleteNode(idDelete);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery) {
      const expandAllItems = (items) => {
        let ids = [];
        items.forEach((item) => {
          ids.push(item.id.toString());
          if (item.children) {
            ids = ids.concat(expandAllItems(item.children));
          }
        });
        return ids;
      };
      setExpandedItems(expandAllItems(items));
    } else {
      setExpandedItems([]);
    }
  }, [debouncedSearchQuery, items]);

  const filterItems = (items, query) => {
    if (!query) return items;
    return items
      .map((item) => {
        if (item.children) {
          const filteredChildren = filterItems(item.children, query);
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
        }
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredItems = filterItems(items, debouncedSearchQuery);

  return (
    <Box sx={{ maxHeight: 500, overflowY: 'auto' }}>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 1
        }}
      >
        <InputField
          type="text"
          placeholder="Buscar..."
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {selectedNode && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 1
            }}
          >
            <Typography variant="h6">{selectedNode.name}</Typography>
            {deleteAction && selectedNode.canDelete && (
              <DeleteButton
                onConfirm={() => handleClickDelete(selectedNode.id)}
                permission={permission ?? ''}
              />
            )}
          </Box>
        )}
      </Box>

      <Scrollbar>
        <RichTreeView
          aria-label="TreeView"
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          experimentalFeatures={{ indentationAtItemLevel: true }}
          items={filteredItems}
          slots={{
            item: CustomTreeItem
          }}
          slotProps={{}}
          getItemId={getItemId}
          getItemLabel={getItemLabel}
          isItemDisabled={isItemDisabled}
          apiRef={apiRef}
          onItemSelectionToggle={handleItemSelectionToggle}
          sx={{
            minHeight: 50
          }}
        />
      </Scrollbar>
    </Box>
  );
};

CommonRichTreeView.propTypes = {
  title: Proptypes.string,
  setSelectedNodeDataItem: Proptypes.func,
  onSelectNode: Proptypes.func,
  onDeleteNode: Proptypes.func,
  deleteAction: Proptypes.bool,
  onLevelChange: Proptypes.func,
  permission: Proptypes.string,
  items: Proptypes.array
};
