import { Navigate } from 'react-router-dom';
import { AllowedGuard } from '../hooks/use-allowed';

import { lazy } from 'react';
import { Loadable } from './loadable';
import { GadminActions } from '../contexts/gadmin-actions';

const CreditNoteService = Loadable(
  lazy(() =>
    import('../containers/purchases/credit-note-service').then((module) => ({
      default: module.CreditNoteService
    }))
  )
);

const CreditNotesItems = Loadable(
  lazy(() =>
    import('../containers/purchases/credit-notes-items').then((module) => ({
      default: module.CreditNotesItems
    }))
  )
);

const CreditNotesService = Loadable(
  lazy(() =>
    import('../containers/purchases/credit-notes-service').then((module) => ({
      default: module.CreditNotesService
    }))
  )
);

const DebitNotesService = Loadable(
  lazy(() =>
    import('../containers/purchases/debit-notes-service').then((module) => ({
      default: module.DebitNotesService
    }))
  )
);

const InvoiceItem = Loadable(
  lazy(() =>
    import('../containers/purchases/invoice-item').then((module) => ({
      default: module.InvoiceItem
    }))
  )
);

const Invoices = Loadable(
  lazy(() =>
    import('../containers/purchases/invoices').then((module) => ({
      default: module.Invoices
    }))
  )
);

const InvoicesItem = Loadable(
  lazy(() =>
    import('../containers/purchases/invoices-item').then((module) => ({
      default: module.InvoicesItem
    }))
  )
);

const ReceiptsFees = Loadable(
  lazy(() =>
    import('../containers/purchases/receipts-fees').then((module) => ({
      default: module.ReceiptsFees
    }))
  )
);

const Invoice = Loadable(
  lazy(() =>
    import('../containers/purchases/invoice').then((module) => ({
      default: module.Invoice
    }))
  )
);

const ReceiptFees = Loadable(
  lazy(() =>
    import('../containers/purchases/receipt-fees').then((module) => ({
      default: module.ReceiptFees
    }))
  )
);

export const PurchaseRoutes = [
  { path: '', element: <Navigate to="/app/purchases/invoices" replace /> },
  {
    path: 'invoices',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
            <Invoices />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
            <Invoice />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'invoice-items',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_ITEM_LIST}>
            <InvoicesItem />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_ITEM_LIST}>
            <InvoiceItem />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'receipts-fees',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_LIST}>
            <ReceiptsFees />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_RRHH_LIST}>
            <ReceiptFees />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'nc-items',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
            <CreditNotesItems />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_FFEE_LIST}>
            <CreditNotesItems />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'nc-service',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
            <CreditNotesService />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
            <CreditNoteService />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'dn-service',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
            <DebitNotesService />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_PURCHASE_NCES_LIST}>
            <DebitNotesService />
          </AllowedGuard>
        )
      }
    ]
  }
];
