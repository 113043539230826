import { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack
} from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { Plus as PlusIcon } from '../../../icons/plus';
import { InputField } from '../../input-field';
import { Search } from '../../../icons/search';
import { PropertyList } from '../../property-list';
import { ConfirmationDialogCustomer } from '../../../containers/busines-partners/customer-create-modal';
import { useApp } from '../../../hooks/use-app';
import { goalBaseBusinessPartnersApi } from '../../../api/goal/base/goal-base-business-partners';
import { Eye } from '../../../icons/eye';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { InputLabelField, SelectInputField } from '../../select-field';
import { AutocompleteField } from '../../autocomplete-field';
import { Trash as TrashIcon } from '../../../icons/trash';
import { goalInventoryApi } from '../../../api/goal/inventory/goal-inventory';
import MultiSelectField from '../../common/multi-select-field';
import useFormikMultiSelect from '../../../hooks/use-formik-multiselect';
import { ConstantStatus } from '../../../api/application-enums';
import { useBolean } from '../../../hooks/use-bolean';
import { departamentsApi } from '../../../api/goal/base/departaments-api';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { goalMaintenanceBaseApi } from '../../../api/goal/maintenance/goal-maintenance-base';

export const BusinessPartnersDetailForm = ({
  initialValues = {},
  update = false,
  onClose = () => {},
  handleRefresh
}) => {
  const { currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const [typeOfPartner, setTypeOfPartner] = useState([]);
  const [typeOfPartnerState, setTypeOfPartnerState] = useState(0);
  const [businessPersonType, setBusinessPersonType] = useState([]);
  const [businesGroupType, setBusinesGroupType] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [businessDocumentType, setBusinessDocumentType] = useState([]);
  const [employeePositions, setEmployeePositions] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [labelsListData, setLabelsListData] = useState([]);
  // const [selectedIdType, setSelectedIdType] = useState({});

  const [labelsListDataOriginal, setLabelsListDataOriginal] = useState([]);

  const [flags, setFlags] = useState({
    isCustomer: false,
    isVendor: false,
    isEmployee: false,
    isFreelancer: false,
    isCompany: false,
    isPerson: false,
    isForeign: false,
    loaded: false,
    selectedPersonType: null,
    selectedDocumentType: null,
    isNew: false
  });
  const { isTrue } = useBolean();

  const handleSelectDocumentType = (value) => {
    const selected = businessDocumentType.find((item) => item.id === value) ?? {};
    setFlags((prevFlags) => ({
      ...prevFlags,
      selectedDocumentType: selected
    }));

    return selected;
  };

  const handleSelectPersonType = (value) => {
    const selected = businessPersonType.find((item) => item.code === value) ?? {};
    setFlags((prevFlags) => ({
      ...prevFlags,
      selectedPersonType: selected
    }));
    return selected;
  };

  const [departamentsState, handleRefreshDepartaments] = useData({
    sourceApi: departamentsApi.getDepartamentsSimpleList,
    loadingMessage: 'Cargando departamentos...',
    mounted,
    apiParameter: { companyId: currentCompany },
    defaults: { noAutoLoad: true }
  });

  const [branchesState, handleRefreshBranches] = useData({
    sourceApi: goalMaintenanceBaseApi.getBranchesSimpleList,
    loadingMessage: 'Cargando sedes...',
    mounted,
    apiParameter: { companyId: currentCompany },
    defaults: { noAutoLoad: true }
  });

  useEffect(() => {
    if (flags.loaded) {
      const selectedType = flags.selectedPersonType;
      const selectedDocType = flags.selectedDocumentType;
      setFlags((prevFlags) => ({
        ...prevFlags,
        isCustomer: isTrue(initialValues.is_customer),
        isVendor: isTrue(initialValues.is_vendor),
        isEmployee: isTrue(initialValues.is_employee),
        isFreelancer: isTrue(initialValues.is_freelancer),
        isCompany: isTrue(selectedType?.isCompany),
        isPerson: isTrue(selectedType?.isPerson),
        isForeign: isTrue(selectedType?.isForeign),
        isRUC: isTrue(selectedDocType?.properties?.isRUC),
        isDNI: isTrue(selectedDocType?.properties?.isDNI),
        isNew: !update
      }));
    }
  }, [initialValues, flags.loaded]);
  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    getCustomerDataType();
  }, [typeOfPartnerState]);

  // businessType
  const getCustomerData = async () => {
    const [
      typeOfPartnerData,
      bussinessPersonTypeData,
      bussinessTypeData,
      bussinessDocumentTypeData,
      employeePositionData,
      paymentTermsData,
      labelsData
    ] = await Promise.all([
      goalBaseBusinessPartnersApi.getBussinesPartnerTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerPersonTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerBusinessTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerDocumentTypes(),
      goalBaseBusinessPartnersApi.getBussinesPartnerEmployeePosition(currentCompany),
      goalBaseBusinessPartnersApi.getBussinesPartnerTPaymentTerms(currentCompany),
      goalInventoryApi.getLabelsResources()
    ]);

    if (typeOfPartnerData.success && typeOfPartnerData.data.length !== 0) {
      setTypeOfPartner(typeOfPartnerData.data);
    }
    if (bussinessPersonTypeData.success && bussinessPersonTypeData.data.length !== 0) {
      setBusinessPersonType(bussinessPersonTypeData.data);
    }
    if (bussinessTypeData.success && bussinessTypeData.data.length !== 0) {
      setBusinessType(bussinessTypeData.data);
    }
    if (bussinessDocumentTypeData.success && bussinessDocumentTypeData.data.length !== 0) {
      setBusinessDocumentType(bussinessDocumentTypeData.data);
    }
    if (employeePositionData.success && employeePositionData.data.length !== 0) {
      setEmployeePositions(employeePositionData.data);
    }
    if (paymentTermsData.success && paymentTermsData.data.length !== 0) {
      setPaymentTerms(paymentTermsData.data);
    }
    if (labelsData.success && labelsData.data.length !== 0) {
      setLabelsListData(labelsData.data);
      setLabelsListDataOriginal(labelsData.data);
    } else if (update) {
      setLabelsListData(initialValues.labels);
      setLabelsListDataOriginal(initialValues.labels);
    }
    handleSelectPersonType(initialValues.person_type);
    handleSelectDocumentType(initialValues.tax_id_type_id);

    setFlags((prevFlags) => ({
      ...prevFlags,
      loaded: true
    }));
  };

  const getCustomerDataType = async () => {
    if (formik.values.type !== '') {
      const bussinessTypeGroupState =
        await goalBaseBusinessPartnersApi.getBussinesPartnerResourcesGroup({
          companyId: currentCompany,
          typeCode: formik.values.type
        });
      if (bussinessTypeGroupState.success) {
        if (bussinessTypeGroupState.data.length !== 0) {
          setBusinesGroupType(bussinessTypeGroupState.data);
        }
      }
    }
  };

  const validatedValue = (value) => {
    const valueNum = parseFloat(value);
    if (!Number.isNaN(valueNum) && valueNum !== null) {
      return valueNum;
    }
    return '';
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialValues.company_id || currentCompany ? currentCompany : '',
      business_partner_group_id: initialValues.business_partner_group_id || '',
      business_partner_group: initialValues.business_partner_group || {},
      name: initialValues.name || '',
      full_name: initialValues.full_name || '',
      person_type: initialValues.person_type || '',
      first_name: initialValues.first_name || '',
      last_name: initialValues.last_name || '',
      last_name2: initialValues.last_name2 || '',
      comercial_name: initialValues.comercial_name || '',
      customer_code: initialValues.customer_code || '',
      vendor_code: initialValues.vendor_code || '',
      tax_id_type_id: validatedValue(initialValues.tax_id_type_id) || '',
      tax_id: initialValues.tax_id || '',
      business_type_id: initialValues.business_type_id || '',
      preferd_channel_id: initialValues.preferd_channel_id || '',
      email: initialValues.email || '',
      is_customer: initialValues.is_customer || 'N',
      customer_status: initialValues.customer_status || 'A',
      is_vendor: initialValues.is_vendor || 'N',
      vendor_status: initialValues.vendor_status || 'N',
      is_employee: initialValues.is_employee || 'N',
      employee_status: initialValues.employee_status || 'N',
      is_freelancer: initialValues.is_freelancer || 'N',
      license_number: initialValues.license_number || '',
      employee_position_id: initialValues.employee_position_id || '',
      branch_id: initialValues.branch_id || '',
      branch: initialValues.branch || {},
      departament_id: initialValues.departament_id || '',
      departament: initialValues.departament || {},
      type: initialValues.type || '',
      buy_payment_term_id: initialValues.buy_payment_term_id || '',
      paymentTermBuyInfo: initialValues.buy_payment_term || '',
      sell_payment_term_id: initialValues.sell_payment_term_id || '',
      paymentTermSellInfo: initialValues.paymentTermSellInfo || '',
      seller_id: initialValues.seller_id || '',
      labels: initialValues.labels || [],
      status: initialValues.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      person_type: Yup.string().required('Este campo es obligatorio')
      // tax_id_type_id: Yup.string().required('Este campo es obligatorio'),
      // tax_id: Yup.string().required('Este campo es obligatorio'),
      // address: Yup.string().required('Este campo es obligatorio'),
      // type: Yup.string().required('Este campo es obligatorio'),
      // business_type_id: Yup.string().required('Este campo es obligatorio'),
      // comercial_name: Yup.string().required('Este campo es obligatorio'),
      // email: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values) => {
      const newValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (value === '') {
          newValues[key] = null;
        } else {
          newValues[key] = value;
        }
      });
      if (newValues.tax_id_type_id !== 1) {
        newValues.full_name = `${newValues.first_name} ${newValues.last_name} ${newValues.last_name2}`;
        newValues.name = `${newValues.first_name} ${newValues.last_name} ${newValues.last_name2}`;
      }
      const response = await goalBaseBusinessPartnersApi.updateCustomerForm({
        id: initialValues.id,
        values: newValues
      });
      if (response.success) {
        showNotify(`${response?.message}. Cambio exitoso `);
        onClose(false);
        handleRefresh();
      } else {
        showError(`${response?.message}. No se proceso la acción `);
      }
    }
  });

  const handleTypePersonLegal = () => {
    formik.setFieldValue('tax_id_type_id', 1);
  };
  const handleTypePersonNatural = () => {
    formik.setFieldValue('tax_id_type_id', 3);
  };
  const handleSearch = async () => {
    // if (formik.values.type === '') {
    //   setErrorMessage('Seleccione un tipo de socio');
    //   setTimeout(() => {
    //     setErrorMessage(null);
    //   }, 3000);
    //   return;
    // }
    if (formik.values.tax_id_type_id === '') {
      setErrorMessage('Seleccione un documento');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }
    if (flags.isDNI && formik.values.tax_id.length !== 8) {
      setErrorMessage('Ingrese un número válido de DNI');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }
    if (flags.isRUC && formik.values.tax_id.length !== 11) {
      setErrorMessage('Ingrese un número válido de RUC');
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    const resp = await goalBaseBusinessPartnersApi.getBussinesPartnerSearchId(
      currentCompany,
      formik.values.type,
      formik.values.tax_id,
      formik.values.tax_id_type_id
    );
    // && resp.data.length > 0
    if (resp.success && resp.data.length > 0) {
      setModalOpen(true);
      setConfirmDialogMessage('El cliente ya esta registrado, ¿Quieres continuar?');
      setListCustomer(resp.data);
    } else if (resp.success && resp.data.length === 0) {
      handleConfirm();
    } else {
      setListCustomer([]);
    }
  };

  const { handleMultiSelectChange, getOptionsName, handleOptionsDelete } =
    useFormikMultiSelect(formik);

  const handleConfirm = async () => {
    if (formik.values.tax_id_type_id !== 1) {
      setModalOpen(false);
      return;
    }
    const result = await goalBaseBusinessPartnersApi.getBussinesPartnerSearchRUC(
      formik.values.tax_id
    );
    if (result.success) {
      const { nombre } = result.data;
      formik.setFieldValue('full_name', nombre);
    } else {
      setErrorMessage(result.apiMessage);
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
    setModalOpen(false);
  };
  const handleChangeType = () => {
    setTypeOfPartnerState(typeOfPartnerState + 1);
    formik.setFieldValue('business_partner_group_id', '');
  };

  const handleChangeChip = (value, formikChange) => {
    switch (formikChange) {
      case 'is_customer':
        break;
      case 'is_vendor':
        break;
      case 'is_employee':
        if (value === 'N') {
          handleRefreshDepartaments({ companyId: currentCompany });
          handleRefreshBranches({ companyId: currentCompany });
        }
        break;
      case 'is_freelancer':
        break;
    }

    if (value === 'Y') {
      formik.setFieldValue(formikChange, 'N');
    } else {
      formik.setFieldValue(formikChange, 'Y');
    }
  };

  useEffect(() => {
    const selectedType = flags.selectedPersonType;
    const selectedDocType = flags.selectedDocumentType;
    setFlags((prevFlags) => ({
      ...prevFlags,
      isCustomer: isTrue(formik.values.is_customer),
      isVendor: isTrue(formik.values.is_vendor),
      isEmployee: isTrue(formik.values.is_employee),
      isFreelancer: isTrue(formik.values.is_freelancer),
      isCompany: isTrue(selectedType?.isCompany),
      isPerson: isTrue(selectedType?.isPerson),
      isForeign: isTrue(selectedType?.isForeign),
      isRUC: isTrue(selectedDocType?.properties?.isRUC),
      isDNI: isTrue(selectedDocType?.properties?.isDNI)
    }));
  }, [formik.values]);

  return (
    <Box
      sx={{
        minWidth: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRadius: 2,
        padding: 2
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={8}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item md={4} xs={12}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Socio de negocio
            </InputLabel>
            <PropertyList>
              {/* <InputField
                  select
                  name="company"
                  label="Empresa"
                  fullWidth
                  value={currentCompany}
                  disabled
                  sx={{ marginBottom: '1em' }}
                >
                  {company.length === 0 ? (
                    <MenuItem value="">No hay datos</MenuItem>
                  ) : (
                    company.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.short_name}
                      </MenuItem>
                    ))
                  )}
                </InputField> */}
              <Box sx={{ display: 'flex', gap: '1em' }}>
                {false && (
                  <InputField
                    select
                    label="Tipo de socio"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    fullWidth
                    sx={{ marginBottom: '1em' }}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    disabled
                  >
                    {typeOfPartner.length === 0 ? (
                      <MenuItem value="">No hay datos</MenuItem>
                    ) : (
                      typeOfPartner.map((item, index) => (
                        <MenuItem key={index} value={item.code} onClick={handleChangeType}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </InputField>
                )}
              </Box>
              <InputField
                select
                label="Tipo Persona"
                name="person_type"
                value={businessPersonType.length === 0 ? '' : formik.values.person_type}
                onChange={(e) => {
                  if (handleSelectPersonType(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                fullWidth
                error={formik.touched.person_type && Boolean(formik.errors.person_type)}
                helperText={formik.touched.person_type && formik.errors.person_type}
                sx={{ marginBottom: '1em' }}
              >
                {businessPersonType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businessPersonType.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.code}
                      onClick={
                        item.code === 'TPJ' ? handleTypePersonLegal : handleTypePersonNatural
                      }
                    >
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
              <InputField
                select
                label="Documento de identidad"
                name="tax_id_type_id"
                value={businessDocumentType.length === 0 ? '' : formik.values.tax_id_type_id}
                onChange={(e) => {
                  if (handleSelectDocumentType(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                fullWidth
                error={formik.touched.tax_id_type_id && Boolean(formik.errors.tax_id_type_id)}
                helperText={formik.touched.tax_id_type_id && formik.errors.tax_id_type_id}
                disabled={formik.values.person_type === 'TPJ'}
                sx={{ marginBottom: '1em' }}
              >
                {businessDocumentType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businessDocumentType.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
              {!flags.isRUC && (
                <>
                  <InputField
                    placeholder="Número Doc."
                    name="tax_id"
                    value={formik.values.tax_id}
                    onChange={formik.handleChange}
                    label="Número de Documento"
                    fullWidth
                    error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
                    helperText={formik.touched.tax_id && formik.errors.tax_id}
                    sx={{ marginBottom: '1em' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                          <Search onClick={handleSearch} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errorMessage && (
                    <Alert sx={{ marginBottom: 2 }} severity="warning">
                      {errorMessage}
                    </Alert>
                  )}
                </>
              )}
              {flags.isRUC && (
                <>
                  <InputField
                    placeholder="Ingrese RUC"
                    name="tax_id"
                    value={formik.values.tax_id}
                    onChange={formik.handleChange}
                    label="RUC"
                    fullWidth
                    error={formik.touched.tax_id && Boolean(formik.errors.tax_id)}
                    helperText={formik.touched.tax_id && formik.errors.tax_id}
                    sx={{ marginBottom: '1em' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer', marginRight: 1 }}>
                          <Search onClick={handleSearch} />
                        </InputAdornment>
                      )
                    }}
                  />
                  {errorMessage && (
                    <Alert sx={{ marginBottom: 2 }} severity="warning">
                      {errorMessage}
                    </Alert>
                  )}
                </>
              )}
              {flags.isPerson && (
                <>
                  <InputField
                    name="last_name"
                    label="Apellido paterno"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    sx={{ marginBottom: '1em' }}
                  />
                  <InputField
                    name="last_name2"
                    label="Apellido materno"
                    value={formik.values.last_name2}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.last_name2 && Boolean(formik.errors.last_name2)}
                    helperText={formik.touched.last_name2 && formik.errors.last_name2}
                    sx={{ marginBottom: '1em' }}
                  />
                  <InputField
                    name="first_name"
                    label="Nombres"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    sx={{ marginBottom: '1em' }}
                  />
                </>
              )}

              {flags.isCompany && (
                <>
                  <InputField
                    name="name"
                    label="Nombre corto"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    sx={{ marginBottom: '1em' }}
                  />
                  <InputField
                    name="full_name"
                    label="Nombre completo"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                    fullWidth
                    error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                    helperText={formik.touched.full_name && formik.errors.full_name}
                    sx={{ marginBottom: '1em' }}
                    disabled={formik.values.tax_id_type_id === 1}
                  />
                </>
              )}
              <InputLabel
                sx={{
                  marginBottom: 1,
                  marginLeft: 0,
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                Información empresa
              </InputLabel>
              <InputField
                name="comercial_name"
                label="Nombre comercial"
                value={formik.values.comercial_name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.comercial_name && Boolean(formik.errors.comercial_name)}
                helperText={formik.touched.comercial_name && formik.errors.comercial_name}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{ marginBottom: '1em' }}
              />
              <InputLabelField title="Giro de Negocio" />
              <SelectInputField
                label="Giro de Negocio"
                name="business_type_id"
                value={businessType.length === 0 ? '' : formik.values.business_type_id}
                onChange={formik.handleChange}
                fullWidth
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                      width: '250px'
                    }
                  }
                }}
              >
                {businessType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businessType.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </SelectInputField>
              {formik.touched.business_type_id && formik.errors.business_type_id && (
                <FormHelperText error>{formik.errors.business_type_id}</FormHelperText>
              )}
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <PropertyList>
              <Chip
                label="Cliente"
                variant={formik.values.is_customer === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.is_customer === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.is_customer, 'is_customer')}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                select
                label="Grupo de cliente"
                name="business_partner_group_id"
                value={businesGroupType.length === 0 ? '' : formik.values.business_partner_group_id}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.business_partner_group_id &&
                  Boolean(formik.errors.business_partner_group_id)
                }
                helperText={
                  formik.touched.business_partner_group_id &&
                  formik.errors.business_partner_group_id
                }
                sx={{ marginBottom: '1em' }}
                disabled={!flags.isCustomer}
              >
                {businesGroupType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businesGroupType.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
              <InputField
                name="customer_code"
                label="Código cliente"
                value={formik.values.customer_code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.customer_code && Boolean(formik.errors.customer_code)}
                helperText={formik.touched.customer_code && formik.errors.customer_code}
                sx={{ marginBottom: '1em' }}
                disabled={flags.isCustomer}
              />
              <AutocompleteField
                label="Termino de pago al cliente"
                name="paymentTermSellInfo"
                placeholder="Seleccione termino"
                fullWidth
                options={paymentTerms || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('paymentTermSellInfo', value);
                  formik.setFieldValue('sell_payment_term_id', value?.id);
                }}
                value={formik.values.paymentTermSellInfo}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.sell_payment_term_id && Boolean(formik.errors.sell_payment_term_id)
                }
                helperText={
                  formik.touched.sell_payment_term_id && formik.errors.sell_payment_term_id
                }
                disabled={!flags.isNew}
              />
              <InputField
                select
                label="Canal Preferido"
                name="preferd_channel_id"
                value={formik.values.preferd_channel_id}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.preferd_channel_id && Boolean(formik.errors.preferd_channel_id)
                }
                helperText={formik.touched.preferd_channel_id && formik.errors.preferd_channel_id}
                sx={{ marginBottom: '1em' }}
                disabled={!flags.isCustomer}
              >
                <MenuItem value="1">Heladero</MenuItem>
                <MenuItem value="2">Minimayorista</MenuItem>
              </InputField>
              <Chip
                label="Proveedor"
                variant={formik.values.is_vendor === 'Y' ? 'filled' : 'outlined'}
                color={formik.values.is_vendor === 'Y' ? 'primary' : 'default'}
                onClick={() => handleChangeChip(formik.values.is_vendor, 'is_vendor')}
                sx={{ marginBottom: '1em' }}
              />
              <InputField
                select
                label="Grupo de proveedor"
                name="business_partner_group_id"
                value={businesGroupType.length === 0 ? '' : formik.values.business_partner_group_id}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.business_partner_group_id &&
                  Boolean(formik.errors.business_partner_group_id)
                }
                helperText={
                  formik.touched.business_partner_group_id &&
                  formik.errors.business_partner_group_id
                }
                sx={{ marginBottom: '1em' }}
                disabled={!flags.isVendor}
              >
                {businesGroupType.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  businesGroupType.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
              <InputField
                name="vendor_code"
                label="Código proveedor"
                value={formik.values.vendor_code}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.vendor_code && Boolean(formik.errors.vendor_code)}
                helperText={formik.touched.vendor_code && formik.errors.vendor_code}
                sx={{ marginBottom: '1em' }}
                disabled={!flags.isNew}
              />
              <AutocompleteField
                label="Termino de pago al proveedor"
                name="paymentTermBuyInfo"
                placeholder="Seleccione termino"
                fullWidth
                options={paymentTerms || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('paymentTermBuyInfo', value);
                  formik.setFieldValue('buy_payment_term_id', value?.id);
                }}
                value={formik.values.paymentTermBuyInfo}
                sx={{ marginBottom: '1em' }}
                error={
                  formik.touched.buy_payment_term_id && Boolean(formik.errors.buy_payment_term_id)
                }
                helperText={formik.touched.buy_payment_term_id && formik.errors.buy_payment_term_id}
                disabled={flags.isVendor}
              />
            </PropertyList>
          </Grid>
          <Grid item md={4} xs={12}>
            <InputLabel
              sx={{
                marginBottom: 1,
                marginLeft: 0,
                color: 'black',
                fontWeight: 'bold'
              }}
              disabled={!formik.values.is_employee}
            >
              Información empleado
            </InputLabel>
            <PropertyList>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                <Chip
                  label="Empleado"
                  variant={formik.values.is_employee === 'Y' ? 'filled' : 'outlined'}
                  color={formik.values.is_employee === 'Y' ? 'primary' : 'default'}
                  onClick={() => handleChangeChip(formik.values.is_employee, 'is_employee')}
                  sx={{ marginBottom: '1em' }}
                />
              </Stack>
              <InputField
                name="employee_number"
                label="Número de empleado"
                value={formik.values.employee_number}
                onChange={formik.handleChange}
                fullWidth
                type="number"
                error={formik.touched.employee_number && Boolean(formik.errors.employee_number)}
                helperText={formik.touched.employee_number && formik.errors.employee_number}
                sx={{ marginBottom: '1em' }}
                disabled={formik.values.is_employee === 'N'}
              />
              <InputField
                select
                label="Puesto"
                name="employee_position_id"
                value={formik.values.employee_position_id}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.employee_position_id && Boolean(formik.errors.employee_position_id)
                }
                helperText={
                  formik.touched.employee_position_id && formik.errors.employee_position_id
                }
                disabled={formik.values.is_employee === 'N'}
                sx={{ marginBottom: '1em' }}
              >
                {employeePositions.length === 0 ? (
                  <MenuItem value="">No hay datos</MenuItem>
                ) : (
                  employeePositions.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </InputField>
              <AutocompleteField
                label="Departamento"
                name="departament"
                placeholder="Seleccione departamento"
                fullWidth
                options={departamentsState?.data || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('departament', value);
                  formik.setFieldValue('departament_id', value?.id);
                }}
                value={formik.values.departament}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.departament_id && Boolean(formik.errors.departament_id)}
                helperText={formik.touched.departament_id && formik.errors.departament_id}
                disabled={formik.values.is_employee === 'N'}
              />
              <AutocompleteField
                label="Sede"
                name="branch"
                placeholder="Seleccione sede"
                fullWidth
                options={branchesState?.data || []}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option) => option.name || ''}
                onChange={(e, value) => {
                  formik.setFieldValue('branch', value);
                  formik.setFieldValue('branch_id', value?.id);
                }}
                value={formik.values.branch}
                sx={{ marginBottom: '1em' }}
                error={formik.touched.branch_id && Boolean(formik.errors.branch_id)}
                helperText={formik.touched.branch_id && formik.errors.branch_id}
                disabled={formik.values.is_employee === 'N'}
              />
              <InputField
                name="license_number"
                label="Licencia de conducir"
                value={formik.values.license_number}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.license_number && Boolean(formik.errors.license_number)}
                helperText={formik.touched.license_number && formik.errors.license_number}
                disabled={formik.values.is_employee === 'N'}
                sx={{ marginBottom: '1em' }}
              />
              <Divider />
              <MultiSelectField
                label="Características"
                name="labels"
                value={labelsListDataOriginal.length === 0 ? [] : formik.values.labels}
                onChange={(e) =>
                  handleMultiSelectChange(e, 'labels', setLabelsListData, true, labelsListData)
                }
                error={formik.touched.labels && Boolean(formik.errors.labels)}
                options={labelsListDataOriginal}
                getOptionName={(value) => getOptionsName(value.code, labelsListDataOriginal)}
                onDeleteOption={(value) =>
                  handleOptionsDelete(
                    value,
                    'labels',
                    formik.values.labels,
                    setLabelsListData,
                    true,
                    labelsListData
                  )
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.is_freelancer === 'Y'}
                    onClick={() => handleChangeChip(formik.values.is_freelancer, 'is_freelancer')}
                    name="is_freelancer"
                    color="secondary"
                    disabled={formik.values.is_employee === 'N'}
                  />
                }
                label="Empleado Externo?"
              />
            </PropertyList>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: 2
          }}
        >
          <Grid item xs={12}>
            <DialogActions>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <LoadingButton
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  variant="contained"
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Guardar
                </LoadingButton>
                {update && (
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<TrashIcon />}
                    onClick={() => onClose(false)}
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    Cancelar
                  </Button>
                )}
              </AllowedGuard>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
      <ConfirmationDialogCustomer
        message={confirmDialogMessage}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
        Component={(props) => <ComponentList {...props} list={listCustomer} />}
      />
    </Box>
  );
};

const ComponentList = ({ list }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [codeRute, setCodeRute] = useState(false);
  const { appUrlBase } = useApp();

  const handleClientRute = (code) => {
    setCodeRute(code);
    setModalOpen(true);
  };
  const handleConfirm = () => {
    navigate(`${appUrlBase}/busines-partners/customers/${codeRute}`, {
      replace: true
    });
    setModalOpen(false);
  };
  return (
    <Box>
      <List sx={{ width: '100%', bgcolor: 'rgba(232, 92, 66, 0.2)' }} aria-label="client">
        {list.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            secondaryAction={
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => handleClientRute(item.cardCode)}
              >
                <Eye />
              </IconButton>
            }
          >
            <ListItemButton>
              <ListItemText primary={item.cardName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ConfirmationDialog
        message="Ir al cliente , perderas los datos del Formulario, ¿Estas seguro de continuar?"
        onCancel={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Box>
  );
};

BusinessPartnersDetailForm.propTypes = {
  initialValues: Proptypes.object,
  update: Proptypes.bool,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func
};

ComponentList.propTypes = {
  list: Proptypes.array
};
