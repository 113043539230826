import PropTypes from 'prop-types';
import { DialogActions, Grid, Switch, Typography } from '@mui/material';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import GadminActions from '../../../contexts/gadmin-actions';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { ConstantStatus, ConstantYesNo } from '../../../api/application-enums';
import GoalButton from '../../goal-button';

const EmployeePositionDetail = ({ item, onEdit, btnEdit = true }) => {
  return (
    <Grid container>
      <Grid item xs={12} xl={6}>
        <PropertyList>
          <PropertyListItem align="end" label="Codigo">
            <Typography color="textSecondary" variant="body2">
              {item?.code ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Empresa">
            <Typography color="textSecondary" variant="body2">
              {item?.company?.short_name ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Nombre">
            <Typography color="textSecondary" variant="body2">
              {item?.name ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Departamento">
            <Typography color="textSecondary" variant="body2">
              {item?.department?.name ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Venta">
            {item?.for_sale ? (
              <Switch
                checked={item.for_sale === ConstantYesNo.YES}
                color={item.for_sale === ConstantYesNo.YES ? 'primary' : 'default'}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{ marginTop: '-7px' }}
              />
            ) : (
              '---'
            )}
          </PropertyListItem>
          <PropertyListItem align="end" label="Número de empleados">
            <Typography color="textSecondary" variant="body2">
              {item?.number_of_employees ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Número de vacantes">
            <Typography color="textSecondary" variant="body2">
              {item?.number_of_vacancies ?? '---'}
            </Typography>
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item xs={12} xl={6}>
        <PropertyList>
          <PropertyListItem align="end" label="Número de empleados requeridos">
            <Typography color="textSecondary" variant="body2" sx={{ pl: 2 }}>
              {item?.number_of_employees_required ?? '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Ruta">
            <Typography color="textSecondary" variant="body2">
              {item?.path ?? '---'}
            </Typography>
          </PropertyListItem>
          {item?.parent && (
            <>
              <PropertyListItem align="end" label="Padre">
                <Typography color="textSecondary" variant="body2">
                  {item.parent?.name ?? '---'}
                </Typography>
              </PropertyListItem>
              <PropertyListItem align="end" label="Ruta padre">
                <Typography color="textSecondary" variant="body2">
                  {item?.parentPath ?? '---'}
                </Typography>
              </PropertyListItem>
            </>
          )}
          <PropertyListItem align="end" label="Cargo en el que depende">
            <Typography color="textSecondary" variant="body2">
              {item?.depends_on?.name || '---'}
            </Typography>
          </PropertyListItem>
          <PropertyListItem align="end" label="Estado">
            {item?.status ? (
              <Switch
                checked={item.status === ConstantStatus.ACTIVE}
                color={item.status === ConstantStatus.ACTIVE ? 'primary' : 'default'}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{ marginTop: '-7px' }}
              />
            ) : (
              '---'
            )}
          </PropertyListItem>
        </PropertyList>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          {btnEdit && (
            <GoalButton
              color="primary"
              startIcon={<PencilIcon />}
              onClick={onEdit}
              variant="contained"
              sx={{ mr: 2 }}
              permission={GadminActions.G2_BULK_LIST}
            >
              Editar
            </GoalButton>
          )}
        </DialogActions>
      </Grid>
    </Grid>
  );
};

EmployeePositionDetail.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func,
  btnEdit: PropTypes.bool
};

export default EmployeePositionDetail;
