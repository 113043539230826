import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { useApp } from '../hooks/use-app';
import GoalButton from '../components/goal-button';
import { useAuth } from '../hooks/use-auth';
import { useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page401() {
  const { appUrlBase, title, isLoggedIn } = useApp();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleCloseSession = () => {
    logout();
  };
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, navigate]);
  return (
    <RootStyle title={title('No autorizado')}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: '60vh', margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3">Ups, no estás autorizado</Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Lo sentimos, no tienes permisos para ver este contenido.
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/unauthorized.svg"
                sx={{ mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <GoalButton
              to={`/dashboard`}
              color="primary"
              component={RouterLink}
              startIcon={<HomeIcon />}
            >
              Inicio
            </GoalButton>
            <GoalButton onClick={handleCloseSession} startIcon={<AccountCircleIcon />}>
              Cambiar de cuenta
            </GoalButton>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
