import { Helmet } from 'react-helmet-async';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import GadminActions from '../../../contexts/gadmin-actions';
import { useApp } from '../../../hooks/use-app';
import { GoalTabs } from '../../../hooks/use-tabs';
import { useSurvey } from '../../../contexts/surveys/survey-context';
import LoadingContent from '../../../components/common/loading-content';
import GoalButton from '../../../components/goal-button';
import { ArrowLeft } from '../../../icons/arrow-left';
import { SurveySurveyItemMenu } from '../../../components/survey/survey/survey-survey-item-menu';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';

const defaultTabs = [
  {
    href: '',
    label: 'Detalle',
    permission: GadminActions.GCC_TDOY_SUMARY
  },
  {
    href: '/assignments',
    label: 'Asignaciones',
    permission: GadminActions.GCC_TDOY_SUMARY
  },
  {
    href: '/results',
    label: 'Resultados',
    permission: GadminActions.GCC_TDOY_SUMARY
  }
];

export const SurveyTab = () => {
  const app = useApp();
  const { id } = useParams();
  const { appUrlBase } = useApp();

  const { loading, survey, handleRefresh, error, view, message } = useSurvey();

  const parentRoute = `${appUrlBase}/survey/survey`;

  const renderContent = () => {
    if (loading || error) {
      return <LoadingContent loadingText={message} />;
    }

    if (survey && survey.length === 0) {
      const message = `No se encontro ninguna encuesta con el id ${id}`;
      return <LoadingContent loadingText={message} />;
    }

    return (
      <Box sx={{ py: 2 }}>
        <Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <GoalButton
              color="primary"
              size="large"
              label="Ver todas las encuestas"
              startIcon={<ArrowLeft />}
              component={RouterLink}
              to={`${parentRoute}`}
              variant="outlined"
            />
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="h5">Encuesta: {survey.title}</Typography>
            <Box sx={{ flexGrow: 1 }} />
            <RefreshButton onClick={handleRefresh} permission={GadminActions.G2_SURVEY_LIST} />
            <SurveySurveyItemMenu items={survey} handleRefresh={handleRefresh} detail={false} />
          </Box>
        </Box>
        <GoalTabs basePath={`${parentRoute}/${survey._id}`} tabs={defaultTabs} params={{ view }} />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Encuesta')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}
        </Container>
      </Box>
    </>
  );
};
