import { Box, Card, CardContent, CardHeader, Container } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { GMGrid } from '../../../components/gm/grid/gm-grid';
import { useApp } from '../../../hooks/use-app';
import { useGMDataSet } from '../../../components/gm/dataset/use-gm-dataset';
import { Helmet } from 'react-helmet-async';
import { LoadingContent } from '../../../components/common/loading-content';
import { RefreshButton } from '../../../components/common/buttons/refresh-button';
import { showError, showNotify } from '../../../hooks/toasty';
import { SyncButton } from '../../../components/common/buttons/sync-button';
import { goalPriceListsApi } from '../../../api/goal/inventory/price-list/goal-price-list';
import { useParams } from 'react-router-dom';

const columns = [
  { field: 'CardCode', headerName: 'Código Proveedor' },
  { field: 'CardName', headerName: 'Proveedor', width: 250 },
  { field: 'lineName', headerName: 'Línea' },
  { field: 'ItemCode', headerName: 'Código Artículo' },
  { field: 'ItemName', headerName: 'Artículo', width: 250 },
  { field: 'Price', headerName: 'Precio', type: 'price' },
  { field: 'taxRate', headerName: 'IGV', type: 'tax' },
  { field: 'priceWithTax', headerName: 'Precio con IGV', type: 'price' },
  { field: 'fixed_price', headerName: 'Precio Fijo', type: 'boolean', width: 150 },
  { field: 'fixed_price_to', headerName: 'Precio Fijo hasta', type: 'date', width: 150 }
];

export const PriceListLines = () => {
  const { id } = useParams();

  const { title, currentCompany } = useApp();
  const mounted = useMounted();

  const { handleRefresh, ...proceListsState } = useGMDataSet({
    sourceApi: goalPriceListsApi.getLines,
    apiParameters: { companyId: currentCompany, id },
    loadingMessage: 'Cargando  detalles de la lista de precios...',
    mounted,
    columns
  });

  const handleSync = async () => {
    try {
      const result = await goalPriceListsApi.syncLines({ companyId: currentCompany, id });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderContent = () => {
    if (proceListsState.error || proceListsState.isLoading || !proceListsState.data) {
      return (
        <LoadingContent
          loadingText={proceListsState.message}
          error={proceListsState.error}
          state={proceListsState}
        />
      );
    }
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
        variant="outlined"
      >
        <CardHeader
          title="Detalle de lista de precios"
          action={
            <>
              <SyncButton onClick={handleSync} />
              <RefreshButton onClick={handleRefresh} />
            </>
          }
        />

        <CardContent>
          <GMGrid {...proceListsState} />
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title> {title('Detalle de lista de precios')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1,
          paddingBottom: '1rem'
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{ py: 4 }}>
            {proceListsState.isLoading ? (
              <LoadingContent
                loadingText={proceListsState.message}
                error={proceListsState.error}
                state={proceListsState}
              />
            ) : (
              renderContent()
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};
