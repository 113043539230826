import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalIncidentsApi } from '../../../api/goal/incidents/goal-incidents';
import { useData } from '../../../hooks/use-data';
import { MaintenanceIncidentCategoriesForm } from '../../../components/maintenance/incidents/maintenance-incident-categories-form';
import { AddButton, RefreshButton } from '../../../components/common/buttons';
import { useGoalTheme } from '../../../hooks/use-goal-theme';
import { MaintenanceDetailDefault } from '../../../components/maintenance/maintenance-detail-default';
import GoalCustomDialog from '../../../components/goal-custom-dialog';
import LoadingContent from '../../../components/common/loading-content';
import { CommonRichTreeView } from '../../../components/common/tree-view/common-rich-tree-view';

export const MaintenanceIncidentCategories = () => {
  const { title, companyState, currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [open, setOpen] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);

  const [incidentCategoriesState, incidentCategoriesRefresh] = useData({
    sourceApi: goalIncidentsApi.getIncidentsCategoriesWithChildren,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando incidencias',
    mounted
  });

  const [configState] = useData({
    sourceApi: goalIncidentsApi.getIncidentsConfig,
    loadingMessage: 'Cargando configuración',
    mounted,
    apiParameter: currentCompany
  });

  const [referenceType] = useData({
    sourceApi: goalIncidentsApi.getIncidentsReferencesTypes,
    loadingMessage: 'Cargando tipos de referencias',
    mounted,
    apiParameter: { companyId: currentCompany }
  });

  const handleRefresh = () => {
    incidentCategoriesRefresh({ companyId: currentCompany });
  };

  const handleClose = () => {
    setEditmode(false);
    setOpen(false);
  };

  const onNodeSelection = (item) => {
    setSelectedNodeDataItem(item);
  };

  const onDeleteNode = async (id) => {
    const response = await goalIncidentsApi.deleteCategory({
      id,
      companyId: currentCompany
    });
    if (response.success) {
      showNotify(response?.message ? response?.message : `Proceso exitoso.`);
      setSelectedNodeDataItem(null);
      handleRefresh();
    } else {
      showError(response?.message ? response?.message : `No se proceso la acción.`);
    }
  };

  useEffect(() => {
    const loadTreeRoot = async () => {
      incidentCategoriesRefresh({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const renderContent = () => {
    if (incidentCategoriesState.isLoading) {
      return (
        <LoadingContent
          title="Categoria de incidencias"
          message="Cargando categorias de incidencias..."
          state={incidentCategoriesState}
        />
      );
    }
    return (
      <Card>
        <Box>
          <Grid
            container
            spacing={8}
            sx={{
              marginBottom: 2
            }}
          >
            <Grid item md={6} xs={12}>
              <CommonRichTreeView
                title="Incidencias..."
                onSelectNode={onNodeSelection}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                items={incidentCategoriesState.data}
                onDeleteNode={onDeleteNode}
                deleteAction
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MaintenanceDetailDefault
                company={companyState?.data}
                onEdit={() => {
                  setEditmode(true);
                  setOpen(true);
                }}
                item={selectedNodeDataItem}
                btnEdit={selectedNodeDataItem != null}
                companyExist={false}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Categorías de incidencias')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', flexGrow: 1 }}>
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="h4">
                Categorías de incidencias
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AddButton onClick={() => setOpen(true)} permission={GadminActions.GCC_TEAM_BRANDS} />
              <RefreshButton onClick={handleRefresh} permission={GadminActions.GCC_TEAM_BRANDS} />
            </Box>
          </Box>

          {renderContent()}

          <GoalCustomDialog
            open={open}
            handleClose={handleClose}
            maxWidth="xs"
            fullScreen={isMobile}
            withoutFooter
          >
            <MaintenanceIncidentCategoriesForm
              isNew={!editMode}
              initialValues={editMode ? selectedNodeDataItem : null}
              configState={configState.isLoading ? {} : configState}
              referenceType={referenceType.isLoading ? [] : referenceType?.data}
              onClose={handleClose}
              handleRefresh={handleRefresh}
            />
          </GoalCustomDialog>
        </Container>
      </Box>
    </>
  );
};
