import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { Plus } from '../../../icons/plus';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { customeDeleveryMonitoringApi } from '../../../api/customer-delivery-monitoring';
import { CustomerDeliveryTeamsTable } from '../../../components/delivery/monitoring/customer-delivery-teams-table';
import { CustomerDeliveryTeamsFilter } from '../../../components/delivery/monitoring/customer-delivery-teams-filter';
import { useDialog } from '../../../hooks/use-dialog';
import { DocumentLineDetailDialog } from '../../../components/purchases/common/document-line-detail-dialog';
import { CustomerDeliveryTeamsAdd } from '../../../components/delivery/monitoring/customer-delivery-teams-add';
import { useAuth } from '../../../hooks/use-auth';
import { useData } from '../../../hooks/use-data';
import { RenderIf } from '../../../components/render-if';

export const DeliveryTeam = () => {
  const query = useQuery();
  const { id } = useParams();
  const queried = {
    orderDate: query.get('date'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();

  const currentLine = {};
  const [editMode, setEditMode] = useState(false);
  const { hasPermission } = useAuth();
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleExitedDialog = () => {};

  useEffect(() => {
    setEditMode(!!currentLine && hasPermission(GadminActions.GCC_TDOY_TEAM_ADD));
  }, [hasPermission, currentLine]);

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: customeDeleveryMonitoringApi.getCustomeTeams,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      id
    }
    // onPostRefresh: handleClearSelected
  });

  const [teamState, handleRefreshData] = useData({
    sourceApi: customeDeleveryMonitoringApi.getSumaryMonitoring,
    apiParameter: {
      id
    },
    defaults: {
      noAutoLoad: true
    },
    loadingMessage: 'Cargando informacion del resumen',
    mounted
  });

  useEffect(() => {
    handleRefreshData();
  }, []);

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  // console.log(returnsState.documents);
  // console.log(returnsState);
  // console.log(teamState?.data?.canAddTeam);

  return (
    <>
      <Helmet>
        <title>{app.title('Documentos')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Equipo de reparto
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.GCC_TDOY_TEAM_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </AllowedGuard>
              <RenderIf condition={teamState?.data?.canAddTeam === 'Y'}>
                {/* gCC_TDOY_TEAM_ASSIGN */}
                <AllowedGuard permission={GadminActions.GCC_TDOY_TEAM_ASSIGN}>
                  <Button
                    color="primary"
                    size="large"
                    startIcon={<Plus />}
                    onClick={handleOpenEditDialog}
                    variant="contained"
                    sx={{ ml: 3 }}
                  />
                </AllowedGuard>
              </RenderIf>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <CustomerDeliveryTeamsFilter
              disabled={returnsState.isLoading}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              onQueryChange={handleQueryChange}
              onViewChange={handleViewChange}
              query={controller.query}
              selectedDocuments={selectedDocuments}
              view={controller.view}
              onRefresh={handleRefresh}
            />
            <Divider />
            <CustomerDeliveryTeamsTable
              error={returnsState.error}
              documents={returnsState.documents}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
          </Card>
        </Container>
        <DocumentLineDetailDialog
          open={editDialogOpen}
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          currentLine={currentLine}
          onAfterSave={handleRefresh}
          Component={CustomerDeliveryTeamsAdd}
          title="Agregar"
          editable={editMode}
        />
      </Box>
    </>
  );
};
