import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
// import gtm from '../../lib/gtm';
import { Plus as PlusIcon } from '../../icons/plus';
import { useDialog } from '../../hooks/use-dialog';
import { useApp } from '../../hooks/use-app';
import { GProcDefaultsTable } from '../../components/gproc/config/gproc-defaults-table';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { GProcConfigEdit } from '../../components/gproc/config/gproc-config-edit';

import LoadingContent from '../../components/common/loading-content';
import { gProcApi } from '../../api/gproc';
import { useMounted } from '../../hooks/use-mounted';

export const GProcDefaults = () => {
  const app = useApp();
  const mounted = useMounted();

  const [currentLine, setCurrentLine] = useState({});
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const handleExitedDialog = () => {};

  const handleOpenDialogWithNew = () => {
    setCurrentLine?.({ id: -1, name: 'Nuevo', value: '', code: 'ST_', module: 0 });
    handleOpenEditDialog?.();
  };

  const [documentState, setDocumentState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });

  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentState(() => ({
        ...documentState,
        isLoading: true,
        message: 'Cargando configuracion de GProc'
      }));
      try {
        const result = await gProcApi.getDefaults();
        if (mounted.current) {
          setDocumentState(() => ({
            ...result,
            isLoading: false,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);

        if (mounted.current) {
          setDocumentState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);

  const renderContent = () => {
    if (documentState.isLoading || documentState.error) {
      return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
    }
    return (
      <>
        <Box sx={{ py: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Typography color="textPrimary" variant="h4">
              Configuración
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                alignItems: 'right',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AllowedGuard permission={GadminActions.GCC_DOC_SUCCESS_ADD}>
                <Box sx={{ mb: 2 }}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    onClick={handleOpenDialogWithNew}
                    variant="contained"
                  />
                </Box>
              </AllowedGuard>
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* <QueuesStats /> */}
        <GProcDefaultsTable
          setCurrentLine={setCurrentLine}
          handleOpenEditDialog={handleOpenEditDialog}
          onRefresh={handleRefresh}
          documentState={documentState}
        />
        <GProcConfigEdit
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          afterSubmit={handleRefresh}
          open={editDialogOpen}
          lineState={currentLine}
          refreshLine={setCurrentLine}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{app.title('Configuración GProc')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          {renderContent()}{' '}
        </Container>
      </Box>
    </>
  );
};
