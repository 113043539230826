import GadminActions from '../../contexts/gadmin-actions';
import { GadminRequest } from '../../services/gadmin';
import { dataLoader } from '../data-loader';

/* eslint class-methods-use-this:0 */

class QueueApi {
  async getQueues(options) {
    const { filters, sort, sortBy, page, query, view, reload } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.GCC_GPROC_QUEUE,
      cacheItem: 'queues',
      apiParams: {
        type: 'list',
        view,
        payload: { filters, sort, sortBy, page, query, reload }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'number', name: 'objType' },
        { type: 'number', name: 'docEntry' }
      ],
      ...options
    });
  }

  async getQueueStatics() {
    const response = await GadminRequest(GadminActions.GCC_GPROC_QUEUE, { type: 'static' });
    return response;
  }

  async getQueue({ objtype, docEntry }) {
    const response = await GadminRequest(GadminActions.GCC_GPROC_QUEUE, {
      type: 'detail',
      payload: { objtype, docEntry }
    });
    return response;
  }

  async updateQueue({ id, changes }) {
    const response = await GadminRequest(GadminActions.GCC_GPROC_QUEUE, {
      type: 'save-header',
      payload: { id, changes }
    });
    return response;
  }

  async processQueue({ action, items }) {
    const response = await GadminRequest(action, {
      type: 'process',
      payload: { action, items }
    });
    return response;
  }
}

export const queueApi = new QueueApi();
