import PropTypes from 'prop-types';

import { Card, CardHeader } from '@mui/material';

import { useApp } from '../../../../hooks/use-app';
import { useMounted } from '../../../../hooks/use-mounted';
import { useGMDataSet } from '../../../gm/dataset/use-gm-dataset';
import { GMGrid } from '../../../gm/grid/gm-grid';
import { RefreshButton } from '../../../common/buttons';
import { goalWarehousesApi } from '../../../../api/goal/inventory/warehouses/warehouse';

const columns = [
  // {
  //   field: 'whscode',
  //   headerName: 'Almacén',
  //   link: {
  //     to: (item) => `/inventory/warehouses/${item.whscode}`,
  //     tooltip: 'Ver detalle del almacén'
  //   }
  // },
  {
    field: 'itemcode',
    headerName: 'Código',
    link: {
      to: (item) => `/inventory/warehouses/${item.itemcode}`,
      tooltip: 'Ver detalle del producto'
    }
  },

  { field: 'ItemName', headerName: 'Nombre', width: 250 },
  { field: 'cardname', headerName: 'Proveedor' },
  { field: 'docdate', headerName: 'Fecha Doc.', type: 'date' },
  { field: 'createdate', headerName: 'Fecha Crea.', type: 'date' },
  { field: 'documentName', headerName: 'Tipo Doc.' },
  { field: 'docnum', headerName: 'Número Doc.', type: 'int' },
  { field: 'price_before_discount', headerName: 'Precio base', type: 'price' },
  { field: 'discount', headerName: 'Descuento', type: 'discount' },
  { field: 'price', headerName: 'Precio', type: 'price' },
  { field: 'quantity', headerName: 'Cantidad', type: 'int' },
  // memo
  { field: 'memo', headerName: 'Comentarios', width: 250 }
];

export const WarehouseIncomingsTable = ({ code }) => {
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { handleRefresh, ...stockState } = useGMDataSet({
    sourceApi: goalWarehousesApi.getIncomings,
    apiParameters: { companyId: currentCompany, warehouseId: code },
    loadingMessage: 'Cargando almacenes...',
    mounted,
    columns
  });

  return (
    <Card
      variant="outlined"
      sx={{
        marginY: '1em'
      }}
    >
      <CardHeader
        title="Detalle de entradas en almacén"
        action={<RefreshButton onClick={handleRefresh} />}
      />

      <GMGrid {...stockState} />
    </Card>
  );
};

WarehouseIncomingsTable.propTypes = {
  code: PropTypes.string.isRequired
};
