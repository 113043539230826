import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import { useData } from '../../../hooks/use-data';
import { MaintenanceDepartmentsForm } from '../../../components/maintenance/Payroll/maintenance-departments-form';
import { useGoalTheme } from '../../../hooks/use-goal-theme';
import { AddButton, RefreshButton } from '../../../components/common/buttons';
import { MaintenanceDetailDefault } from '../../../components/maintenance/maintenance-detail-default';
import GoalCustomDialog from '../../../components/goal-custom-dialog';
import { CommonRichTreeView } from '../../../components/common/tree-view/common-rich-tree-view';
import LoadingContent from '../../../components/common/loading-content';

export const MaintenanceDepartments = () => {
  const { title, companyState, currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [open, setOpen] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);

  const [configState] = useData({
    sourceApi: goalMaintenancePayrollApi.getDepartamentsConfig,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: currentCompany
  });

  const [departamentsState, departamentRefresh] = useData({
    sourceApi: goalMaintenancePayrollApi.getDepartamentsWithChildren,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando departamentos',
    mounted
  });

  const handleRefresh = () => {
    departamentRefresh({ companyId: currentCompany });
  };

  const handleClose = () => {
    setEditmode(false);
    setOpen(false);
  };

  const onNodeSelection = (item) => {
    setSelectedNodeDataItem(item);
  };

  const onDeleteNode = async (id) => {
    const response = await goalMaintenancePayrollApi.deleteDepartamentsId({
      id,
      companyId: currentCompany
    });
    if (response.success) {
      showNotify(response?.message ? response?.message : `Proceso exitoso.`);
      setSelectedNodeDataItem(null);
      handleRefresh();
    } else {
      showError(response?.message ? response?.message : `No se proceso la acción.`);
    }
  };

  useEffect(() => {
    const loadTreeRoot = async () => {
      departamentRefresh({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const renderContent = () => {
    if (departamentsState.isLoading) {
      return (
        <LoadingContent
          title="Departamentos"
          message="Cargando departamentos..."
          state={departamentsState}
        />
      );
    }
    return (
      <Card sx={{ p: 3 }}>
        <Box>
          <Grid container spacing={8} sx={{ mb: 2 }}>
            <Grid item md={6} xs={12}>
              <CommonRichTreeView
                title="Departamentos..."
                onSelectNode={onNodeSelection}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                items={departamentsState.data}
                onDeleteNode={onDeleteNode}
                deleteAction
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MaintenanceDetailDefault
                company={companyState?.data}
                onEdit={() => {
                  setEditmode(true);
                  setOpen(true);
                }}
                item={selectedNodeDataItem}
                btnEdit={selectedNodeDataItem != null}
                emailExist
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Departamentos')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', flexGrow: 1 }}>
        <Container maxWidth="false" sx={{ pb: 5 }}>
          <Box sx={{ py: 3 }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="h4">
                Departamentos
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AddButton onClick={() => setOpen(true)} permission={GadminActions.GCC_TEAM_BRANDS} />
              <RefreshButton onClick={handleRefresh} permission={GadminActions.GCC_TEAM_BRANDS} />
            </Box>
          </Box>

          {renderContent()}

          <GoalCustomDialog
            open={open}
            handleClose={handleClose}
            maxWidth="xs"
            fullScreen={isMobile}
            withoutFooter
          >
            <MaintenanceDepartmentsForm
              isNew={!editMode}
              initialValues={editMode ? selectedNodeDataItem : null}
              configState={configState.isLoading ? {} : configState}
              onClose={handleClose}
              handleRefresh={handleRefresh}
            />
          </GoalCustomDialog>
        </Container>
      </Box>
    </>
  );
};
