import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { usersApi } from '../../api/managment/users';
import { useApp } from '../../hooks/use-app';
import { useMounted } from '../../hooks/use-mounted';
import { ConstantStatus } from '../../api/application-enums';
import { useData } from '../../hooks/use-data';

const UsersContext = createContext();

const useUser = () => {
  return useContext(UsersContext);
};

const UsersProvider = ({ children }) => {
  const { id } = useParams();
  const { currentCompany } = useApp();
  const mounted = useMounted();
  const { search } = useLocation();
  const view = new URLSearchParams(search).get('view')?.toUpperCase() || ConstantStatus.ACTIVE;

  const [editMode, setEditMode] = useState(false);

  const [dataState, handleRefresh] = useData({
    sourceApi: usersApi.getUser,
    apiParameter: { companyId: currentCompany, id: id, view: view },
    loadingMessage: 'Cargando información del usuario...',
    mounted
  });

  useEffect(() => {
    handleRefresh();
  }, [id]);

  const value = {
    view: view,
    loading: dataState.isLoading,
    user: dataState.data ?? {},
    handleRefresh: handleRefresh,
    error: dataState.displayError ? dataState.displayError : false,
    message: dataState.message ?? '',
    editMode,
    setEditMode
  };
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

UsersProvider.propTypes = { children: PropTypes.node.isRequired };

export { UsersProvider, useUser };
