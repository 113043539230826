import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { dataLoader } from './data-loader';

/* eslint class-methods-use-this:0 */

class CustomerDeliveryMonitoringApi {
  async getCustomeDeliveryMonitoring(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,

      mainFilter
      // apiResponse
      // documentsCount: documentsCountProp
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.GCC_TDOY_LIST,
      cacheItem: 'customer-delivery-monitoring',
      apiParams: {
        type: 'diary',
        payload: {
          filters,
          sort,
          sortBy,
          page,
          query,
          reload,
          orderDate: mainFilter?.orderDate,
          view
        }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'id' },
        { type: 'string', name: 'CodigoCliente' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'slpName' },
        { type: 'string', name: 'CodigoZona' }
      ],
      ...options
    });
  }

  async getCustomeDocuments(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      // apiResponse
      // documentsCount: documentsCountProp
      id
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.GCC_TDOY_DET,
      cacheItem: 'customer-documents',
      apiParams: {
        type: 'diary',
        view,
        payload: { filters, sort, sortBy, page, query, reload, id }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'docentry' },
        { type: 'string', name: 'lsname' },
        { type: 'string', name: 'cardname' },
        { type: 'string', name: 'cardcode' }
      ],
      ...options
    });
  }

  async getCustomeSheets(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      // apiResponse
      // documentsCount: documentsCountProp
      id
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.GCC_TDOY_HR_LIST,
      cacheItem: 'customer-documents',
      apiParams: {
        type: 'diary',
        view,
        payload: { filters, sort, sortBy, page, query, reload, dispatchId: id }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'number', name: 'sheetId' },
        { type: 'string', name: 'agentName' },
        { type: 'string', name: 'agentCode' },
        { type: 'string', name: 'dispatchLeaderCode' },
        { type: 'string', name: 'dispatchLeaderName' },
        { type: 'string', name: 'statusName' },
        { type: 'string', name: 'vehiclePlate' }
      ],
      ...options
    });
  }

  async getCustomeTeams(options) {
    const {
      filters,
      sort,
      sortBy,
      page,
      query,
      view,
      reload,
      // apiData,
      // apiResponse
      // documentsCount: documentsCountProp
      id
    } = options;

    const { returnsData, returnsApiResponse } = await dataLoader.load({
      action: GadminActions.GCC_TDOY_TEAM_LIST,
      cacheItem: 'customer-documents',
      apiParams: {
        type: 'diary',
        payload: { filters, sort, sortBy, page, query, reload, dispatchId: id, view }
      },
      // apiResponse,
      // apiData,
      // reload
      ...options
    });

    if (!returnsData || !Array.isArray(returnsData))
      return dataLoader.returnEmpty(returnsApiResponse);

    return dataLoader.returnData({
      returnsData,
      returnsApiResponse,
      searchFields: [
        { type: 'string', name: 'positionName' },
        { type: 'string', name: 'CodigoAlmacen' },
        { type: 'string', name: 'cardName' },
        { type: 'string', name: 'documentNumber' }
      ],
      ...options
    });
  }

  async getSumaryMonitoring({ id, addStatistic }) {
    const response = await GadminRequest(GadminActions.GCC_TDOY_SUMARY, {
      type: 'diary',
      payload: { dispatchId: id, addStatistic }
    });
    return response;
  }

  async getReason({ id, teamId, action }) {
    const response = await GadminRequest(GadminActions.GCC_TDOY_TEAM_UNASSIGN, {
      type: 'get-reasons',
      payload: { dispatchId: id, teamId, action }
    });
    return response;
  }

  async saveUnassign({ id, teamId, reasonId }) {
    const response = await GadminRequest(GadminActions.GCC_TDOY_TEAM_UNASSIGN, {
      type: 'save',
      payload: { dispatchId: id, teamId, reasonId }
    });
    return response;
  }

  async getAssign({ id, teamId, position }) {
    const response = await GadminRequest(GadminActions.GCC_TDOY_TEAM_ASSIGN, {
      type: 'get-employees',
      payload: { dispatchId: id, teamId, position }
    });
    return response;
  }

  async saveAssign({ id, changes }) {
    // console.log('change', changes);
    // console.log('id', id);
    const response = await GadminRequest(GadminActions.GCC_TDOY_TEAM_ASSIGN, {
      type: 'save',
      payload: { id, changes }
    });
    return response;
  }
}

export const customeDeleveryMonitoringApi = new CustomerDeliveryMonitoringApi();
