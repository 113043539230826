// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/FelizAnoNuevo.css */
.mensaje {
  font-size: 10rem;
  color: white;
  text-shadow:
    0 0 10px #fff,
    0 0 20px #fff,
    0 0 30px #fff,
    0 0 40px #ff0400,
    0 0 70px #ff0400,
    0 0 80px #ff0400,
    0 0 100px #ff0400,
    0 0 150px #ff0400;
  /* 0 0 40px #ff0400,
    0 0 70px #fe5e02,
    0 0 80px #ff0400,
    0 0 100px #6b0606,
    0 0 150px #ff0400; */
  text-align: center;
  margin-top: 20%;
}
/*  reduce font  with media query */
@media (max-width: 768px) {
  .mensaje {
    font-size: 5rem;
  }
}

.message-year {
  display: inline-block;
  margin: 0 0.5rem;
  color: yellow;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/animations/new-year.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,gBAAgB;EAChB,YAAY;EACZ;;;;;;;;qBAQmB;EACnB;;;;wBAIsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;AACA,mCAAmC;AACnC;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,iCAAiC;AACnC;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,kCAAkC;AACpC","sourcesContent":["/* src/FelizAnoNuevo.css */\n.mensaje {\n  font-size: 10rem;\n  color: white;\n  text-shadow:\n    0 0 10px #fff,\n    0 0 20px #fff,\n    0 0 30px #fff,\n    0 0 40px #ff0400,\n    0 0 70px #ff0400,\n    0 0 80px #ff0400,\n    0 0 100px #ff0400,\n    0 0 150px #ff0400;\n  /* 0 0 40px #ff0400,\n    0 0 70px #fe5e02,\n    0 0 80px #ff0400,\n    0 0 100px #6b0606,\n    0 0 150px #ff0400; */\n  text-align: center;\n  margin-top: 20%;\n}\n/*  reduce font  with media query */\n@media (max-width: 768px) {\n  .mensaje {\n    font-size: 5rem;\n  }\n}\n\n.message-year {\n  display: inline-block;\n  margin: 0 0.5rem;\n  color: yellow;\n}\n\n.fade-enter {\n  opacity: 0;\n}\n.fade-enter-active {\n  opacity: 1;\n  transition: opacity 500ms ease-in;\n}\n.fade-exit {\n  opacity: 1;\n}\n.fade-exit-active {\n  opacity: 0;\n  transition: opacity 500ms ease-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
