// import PropTypes from 'prop-types';

import { syncSuppliers, syncAll } from '../../../api/goal/sync/goal-sync-master';

import PersonIcon from '@mui/icons-material/Person';

import PeopleIcon from '@mui/icons-material/People';
import { GoalMenu } from '../../common/goal-menu';
import useProcesor from '../../../hooks/use-procesor';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { ConfirmationMenuItem } from '../../../components/common/confirmation-menu-item';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useAuth } from '../../../hooks/use-auth';

export const SyncMenu = () => {
  const { currentCompany, showError, showNotify } = useApp();
  const { procesor: handleSyncSupplier } = useProcesor({
    handler: syncSuppliers
  });
  const { hasPermission } = useAuth();

  const handleSync = async (params) => {
    const result = await syncAll(params);
    if (!result || !result.success) {
      showError('No se pudo sincronizar');
    } else {
      showNotify(result.message);
    }
  };

  const canSync = hasPermission(GadminActions.GCC_GPROC_MNGMT);

  return (
    <GoalMenu label="Sincronizar">
      {canSync && (
        <ConfirmationMenuItem
          startIcon={<PeopleIcon />}
          helpText={'Sincronizar Módulos'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'modules' });
          }}
          label={'Sincronizar Módulos'}
          confirmationMessage={'¿Está seguro que desea sincronizar los módulos?'}
        />
      )}
      {canSync && (
        <ConfirmationMenuItem
          startIcon={<PeopleIcon />}
          helpText={'Sincronizar Roles'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'roles' });
          }}
          label={'Sincronizar Roles'}
          confirmationMessage={'¿Está seguro que desea sincronizar los roles?'}
        />
      )}
      {canSync && (
        <ConfirmationMenuItem
          startIcon={<PeopleIcon />}
          helpText={'Sincronizar  Permisos'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'permissions' });
          }}
          label={'Sincronizar Permisos'}
          confirmationMessage={'¿Está seguro que desea sincronizar los permisos?'}
        />
      )}
      {canSync && (
        <ConfirmationMenuItem
          startIcon={<PersonIcon />}
          helpText={'Sincronizar Usuarios'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'users' });
          }}
          label={'Sincronizar Usuarios'}
          confirmationMessage={'¿Está seguro que desea sincronizar los usuarios?'}
        />
      )}

      {canSync && (
        <ConfirmationMenuItem
          startIcon={<ManageAccountsIcon />}
          helpText={'Sincronizar marcas'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'brands' });
          }}
          label={'Sincronizar marcas'}
          confirmationMessage={'Está seguro que desea sincronizar las marcas'}
        />
      )}

      {canSync && (
        <ConfirmationMenuItem
          startIcon={<ManageAccountsIcon />}
          helpText={'Sincronizar proveedores'}
          onConfirm={async () => {
            await handleSyncSupplier({ companyId: currentCompany });
          }}
          label={'Sincronizar Proveedores'}
          confirmationMessage={'Está seguro que desea sincronizar los proveedores'}
        />
      )}
      {canSync && (
        <ConfirmationMenuItem
          startIcon={<ManageAccountsIcon />}
          helpText={'Sincronizar vendedores'}
          onConfirm={async () => {
            await handleSync({ companyId: currentCompany, type: 'sellers' });
          }}
          label={'Sincronizar vendedores'}
          confirmationMessage={'Está seguro que desea sincronizar los vendedores'}
        />
      )}

      {
        // sync  taxes
        canSync && (
          <ConfirmationMenuItem
            startIcon={<ManageAccountsIcon />}
            helpText={'Sincronizar impuestos'}
            onConfirm={async () => {
              await handleSync({ companyId: currentCompany, type: 'taxes' });
            }}
            label={'Sincronizar impuestos'}
            confirmationMessage={'Está seguro que desea sincronizar los impuestos'}
          />
        )
      }
    </GoalMenu>
  );
};

SyncMenu.propTypes = {};
