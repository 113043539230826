import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Close';
import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import GoalButton from '../goal-button';

export const CommonDialog = (props) => {
  const {
    helpText = '',
    title,
    controller,
    slot,
    slotProps,
    maxWidth = 'zl',
    fullScreen = false,
    sxDialogContent = {}
  } = props;

  const { component: Component, editor: EditorComponent } = slot;
  const { component: componentProps, editor: editorComponentProps } = slotProps;

  const { open, closeDialog, onExited, onAfterSave } = controller;

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    if (controller.editMode) {
      controller.setEditMode(false);
    }
    closeDialog();
  };

  const handleAfterSave = () => {
    closeDialog();
    onAfterSave?.();
    controller.setEditMode(false);
  };

  return (
    <Dialog
      onClose={closeDialog}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      TransitionProps={{
        onExited: () => {
          onExited?.();
        }
      }}
      // {...other}
    >
      {title && (
        <DialogTitle id="scroll-dialog-title" sx={{ padding: '0px' }}>
          <CardHeader
            title={title}
            sx={{ py: 2 }}
            action={
              <>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
        </DialogTitle>
      )}
      <DialogContent dividers sx={{ ...sxDialogContent }}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {helpText}
        </DialogContentText>
        {controller.editMode && (
          <EditorComponent
            {...editorComponentProps}
            onAfterSave={handleAfterSave}
            onClosed={handleClose}
          />
        )}
        {!controller.editMode && (
          <Component
            {...componentProps}
            setEditMode={editorComponentProps ? controller.setEditMode : null}
            onClosed={handleClose}
            onAfterSave={handleAfterSave}
          />
        )}
      </DialogContent>
      <DialogActions>
        <GoalButton startIcon={<CancelIcon />} onClick={handleClose}>
          Cerrar
        </GoalButton>
      </DialogActions>
    </Dialog>
  );
};

CommonDialog.propTypes = {
  title: PropTypes.string,
  helpText: PropTypes.string,
  maxWidth: PropTypes.string,
  controller: PropTypes.object,
  slot: PropTypes.object,
  slotProps: PropTypes.object,
  fullScreen: PropTypes.bool,
  sxDialogContent: PropTypes.object
};
