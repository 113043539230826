import { dataLoader } from '../../../data-loader';

const endpointLocal = 'accounting/sunat/sire-sales';

class SireSalesApi {
  async getPeriods({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/periods`,
      params: { companyId }
    });
    return response;
  }

  async synchronizeProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/synchronize-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async listProposal({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-proposal`,
      params: { companyId }
    });
    return response;
  }
}

export const sireSalesApi = new SireSalesApi();
