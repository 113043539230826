import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';

import { useMounted } from '../../../hooks/use-mounted';
import { useSelection } from '../../../hooks/use-selection';
import { Refresh as RefreshIcon } from '../../../icons/refresh';

import gtm from '../../../lib/gtm';
import { useApp } from '../../../hooks/use-app';

import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { useQuery } from '../../../hooks/use-query';
import { useDataBrowser } from '../../../hooks/use-data-browser';
import { customeDeleveryMonitoringApi } from '../../../api/customer-delivery-monitoring';
import { CustomerDeliveryDocumentsFilter } from '../../../components/delivery/monitoring/customer-delivery-documents-filter';
import { CustomerDeliveryLiquidationTable } from '../../../components/delivery/monitoring/customer-delivery-liquidation-table';
import { Plus as PlusIcon } from '../../../icons/plus';
import { RenderIf } from '../../../components/render-if';
import { CustomerModal } from '../../../components/delivery/monitoring/customer-modal';
import { LiquidationForm } from '../../../components/delivery/monitoring/form/liquidation-form';
import { CustomerLiquidationItemMenu } from '../../../components/delivery/monitoring/customer-liquidation-item-menu';

const DeliveryLiquidation = () => {
  const query = useQuery();
  const { id } = useParams();
  const queried = {
    orderDate: query.get('date'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const app = useApp();
  const [open, onClose] = useState(false);

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    handleViewChange,
    handlePageChange,
    handleQueryChange,
    handleFiltersClear,
    handleFiltersApply,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: customeDeleveryMonitoringApi.getCustomeDocuments,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      id
    }
    // onPostRefresh: handleClearSelected
  });

  const [selectedDocuments, handleSelect, handleSelectAll] = useSelection(returnsState.documents);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const data = [
    {
      id: 1,
      cod: 'AJI800025',
      name: 'Sazonador Ajinomoto 500Gr',
      warehouse: '11ICPR1',
      calculator: 2,
      not_dispatched: 0,
      counted: 2,
      recounted: 2,
      difference: 0,
      amount: '0.00',
      division: 'tradicional'
    },
    {
      id: 2,
      cod: 'AJI800025',
      name: 'Sillao Ajinosillao 150ML',
      warehouse: '11ICPR1',
      calculator: 2,
      not_dispatched: 0,
      counted: 2,
      recounted: 2,
      difference: 0,
      amount: '0.00',
      division: 'tradicional'
    },
    {
      id: 3,
      cod: 'AJI800025',
      name: 'Sillao Ajinosillao 500ML',
      warehouse: '11ICPR1',
      calculator: 2,
      not_dispatched: 0,
      counted: 2,
      recounted: 2,
      difference: 0,
      amount: '0.00',
      division: 'tradicional'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{app.title('Notas de credito')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="zl"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Liquidacion
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.GCC_BROWSE_VISITA_DIA}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_ADD}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onClose(!open)}
                  variant="contained"
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.G2_CONTESTS_LIST}>
                <CustomerLiquidationItemMenu />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            {false && (
              <CustomerDeliveryDocumentsFilter
                disabled={returnsState.isLoading}
                filters={controller.filters}
                onFiltersApply={handleFiltersApply}
                onFiltersClear={handleFiltersClear}
                onQueryChange={handleQueryChange}
                onViewChange={handleViewChange}
                query={controller.query}
                selectedDocuments={selectedDocuments}
                view={controller.view}
                onRefresh={handleRefresh}
              />
            )}
            <Divider />
            <CustomerDeliveryLiquidationTable
              error={returnsState.error}
              documents={data}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              selectedDocuments={selectedDocuments}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
          </Card>
        </Container>
        <RenderIf condition={open}>
          <CustomerModal open={open} onClose={onClose} permission={GadminActions.G2_CONTESTS_ADD}>
            <LiquidationForm title="Liquidación" onClose={onClose} handleRefresh={handleRefresh} />
          </CustomerModal>
        </RenderIf>
      </Box>
    </>
  );
};

export default DeliveryLiquidation;
