import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import { useData } from '../../../hooks/use-data';
import { MaintenanceEmployeePositionForm } from '../../../components/maintenance/Payroll/maintenance-employee-position-form';
import { AddButton, RefreshButton } from '../../../components/common/buttons';
import { useGoalTheme } from '../../../hooks/use-goal-theme';
import EmployeePositionDetail from '../../../components/maintenance/base/employee-position-detail';
import GoalCustomDialog from '../../../components/goal-custom-dialog';
import { CommonRichTreeView } from '../../../components/common/tree-view/common-rich-tree-view';
import LoadingContent from '../../../components/common/loading-content';

export const MaintenanceEmployeePosition = () => {
  const { title, currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [open, setOpen] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);

  const [employeePositionState, employeePositionRefresh] = useData({
    sourceApi: goalMaintenancePayrollApi.getEmployeePositionsWithChildren,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando puesto de empleados',
    mounted
  });

  const [departamentState] = useData({
    sourceApi: goalMaintenancePayrollApi.getDepartamentsList,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: {
      companyId: currentCompany
    }
  });

  const [configState] = useData({
    sourceApi: goalMaintenancePayrollApi.getEmployeePositionsConfig,
    loadingMessage: 'Cargando informacion de la empresa',
    mounted,
    apiParameter: currentCompany
  });

  const handleRefresh = () => {
    employeePositionRefresh({ companyId: currentCompany });
  };

  const handleClose = () => {
    setEditmode(false);
    setOpen(false);
  };

  const onNodeSelection = (item) => {
    setSelectedNodeDataItem(item);
  };

  const onDeleteNode = async (id) => {
    const response = await goalMaintenancePayrollApi.deleteEmployeePositionsId({
      id,
      companyId: currentCompany
    });
    if (response.success) {
      showNotify(response?.message || `Cambio exitoso `);
      setSelectedNodeDataItem(null);
      handleRefresh();
    } else {
      showError(response?.message || `No se proceso la acción `);
    }
  };

  useEffect(() => {
    employeePositionRefresh({ companyId: currentCompany });
  }, [currentCompany]);

  const renderContent = () => {
    // show loading
    if (employeePositionState.isLoading) {
      return (
        <LoadingContent
          title="Puestos de empleado"
          message="Cargando puestos..."
          state={employeePositionState}
        />
      );
    }

    return (
      <Card sx={{ p: 3 }}>
        <Box>
          <Grid container spacing={8} sx={{ mb: 2 }}>
            <Grid item md={6} xs={12}>
              <CommonRichTreeView
                title="Puestos de empleados..."
                onSelectNode={onNodeSelection}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                disabled={editMode}
                // onLevelChange={setCurrentLevel}
                items={employeePositionState.data}
                onDeleteNode={onDeleteNode}
                deleteAction
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EmployeePositionDetail
                item={selectedNodeDataItem}
                btnEdit={selectedNodeDataItem != null}
                onEdit={() => {
                  setEditmode(true);
                  setOpen(true);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Puestos de empleados')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', flexGrow: 1 }}>
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="h4">
                Puestos de empleados
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AddButton onClick={() => setOpen(true)} permission={GadminActions.GCC_TDOY_LIST} />
              <RefreshButton onClick={handleRefresh} permission={GadminActions.GCC_TDOY_LIST} />
            </Box>
          </Box>

          {renderContent()}

          <GoalCustomDialog
            open={open}
            handleClose={handleClose}
            maxWidth="xs"
            fullScreen={isMobile}
            withoutFooter
          >
            <MaintenanceEmployeePositionForm
              isNew={!editMode}
              initialValues={editMode ? selectedNodeDataItem : null}
              departamentState={departamentState.isLoading ? [] : departamentState?.data}
              configState={configState.isLoading ? {} : configState}
              onClose={handleClose}
              handleRefresh={handleRefresh}
            />
          </GoalCustomDialog>
        </Container>
      </Box>
    </>
  );
};
