import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Box, Button, Card, Container, Divider, Typography } from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { useApp } from '../../hooks/use-app';

import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { useQuery } from '../../hooks/use-query';
import { useDataBrowser } from '../../hooks/use-data-browser';
import { SalesTeamsChannelTable } from '../../components/sales-teams/sales-teams-channel-table';
import { CustomerModal } from '../../components/delivery/monitoring/customer-modal';
import { Plus as PlusIcon } from '../../icons/plus';
import { SalesTeamsChannelForm } from '../../components/sales-teams/form/sales-teams-channel-form';
import { goalSalesTeamsApi } from '../../api/goal/sales-teams/goal-sales-teams';

export const SalesTeamsChannel = () => {
  const { id } = useParams();
  const query = useQuery();
  const [openCreate, onCloseCreate] = useState(false);

  const queried = {
    orderDate: query.get('date') || moment().format('YYYY-MM-DD'),
    type: query.get('type'),
    subtype: query.get('subtype')
  };

  const mounted = useMounted();
  const { title } = useApp();

  useEffect(() => {
    if (queried.query) handleSetController.setMainFilter({ queried });
  }, [queried]);

  const [
    returnsState,
    handleRefresh,
    handleSortChange,
    ,
    handlePageChange,
    ,
    ,
    ,
    controller,
    handleSetController
  ] = useDataBrowser({
    sourceApi: goalSalesTeamsApi.getSalesTeamsListId,
    loadingMessage: 'Buscando...',
    mounted,
    defaults: {
      ...queried,
      view: 'all',
      id
    }
  });

  const data = [
    {
      id: 1,
      cod: '001',
      name: 'MINORISTA',
      predetermined: 'Y',
      active: 'Y'
    },
    {
      id: 2,
      cod: '002',
      name: 'MAYORISTA',
      predetermined: 'N',
      active: 'Y'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{title('Canales')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Typography color="textPrimary" variant="h4">
                Canales
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <AllowedGuard permission={GadminActions.GCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<PlusIcon />}
                  onClick={() => onCloseCreate(!openCreate)}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
              <AllowedGuard permission={GadminActions.GCC_TDOY_LIST}>
                <Button
                  color="primary"
                  size="large"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                  sx={{
                    marginRight: 1
                  }}
                />
              </AllowedGuard>
            </Box>
          </Box>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1
            }}
            variant="outlined"
          >
            <Divider />
            <SalesTeamsChannelTable
              error={returnsState.error}
              // documents={returnsState.documents}
              documents={data}
              documentsCount={returnsState.documentsCount}
              isLoading={returnsState.isLoading}
              onPageChange={handlePageChange}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              sort={controller.sort}
              sortBy={controller.sortBy}
              handleRefresh={handleRefresh}
            />
            <Divider />
          </Card>
        </Container>
        <CustomerModal
          open={openCreate}
          onClose={onCloseCreate}
          permission={GadminActions.G2_CONTESTS_UP}
          widhtForm="xs"
        >
          <SalesTeamsChannelForm title="Canal" onClose={onCloseCreate} />
        </CustomerModal>
      </Box>
    </>
  );
};
