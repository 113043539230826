import { useState } from 'react';
import Proptypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GadminActions from '../../../contexts/gadmin-actions';
import { goalIncidentsApi } from '../../../api/goal/incidents/goal-incidents';
import { Plus as PlusIcon } from '../../../icons/plus';
import { Pencil as PencilIcon } from '../../../icons/pencil';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { X as XIcon } from '../../../icons/x';
import Scrollbar from '../../Scrollbar';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { Trash as TrashIcon } from '../../../icons/trash';
import { Check as CheckIcon } from '../../../icons/check';
import { ConstantStatus } from '../../../api/application-enums';
import { useFormSaver } from '../../../hooks/use-form-saver';
import GoalButton from '../../goal-button';

export const MaintenanceIncidentCategoriesForm = ({
  isNew = true,
  initialValues,
  configState = {},
  referenceType = [],
  onClose,
  handleRefresh
}) => {
  const { currentCompany, showNotify, showError } = useApp();

  const [handleUpdate, handleAdd] = useFormSaver({
    originalData: initialValues,
    apiUpdateHandler: goalIncidentsApi.updateIncidentsForm,
    apiAddHandler: goalIncidentsApi.postIncidentsForm,
    id: 'id',
    includeNewValues: true,
    handleAfterSave: () => {
      formik.resetForm();
      onClose();
      handleRefresh();
    },
    mandatoryFields: ['company_id']
  });

  console.log('referenceType', referenceType);

  const [incidentsValue, setIncidentsValue] = useState(initialValues?.incidents || []);
  const [input, setInput] = useState({
    reference_type: ''
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const formik = useFormik({
    initialValues: {
      id: initialValues?.id || '',
      company_id: initialValues?.company_id || currentCompany,
      name: initialValues?.name || '',
      code: initialValues?.code || '',
      level: initialValues?.level || 1,
      status: initialValues?.status || ConstantStatus.ACTIVE,
      referenceType: initialValues?.referenceType || []
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      if (!configState.success) {
        showError(configState?.message);
        return;
      }

      const level = values.level ?? 1;
      if (
        (configState?.data['max-level'] && configState?.data['max-level'] > parseInt(level, 10)) ||
        configState?.data['max-level'] === -1
      ) {
        const newArrayIncidents = incidentsValue.map((item) => item.reference_type);
        const newValues = {
          ...values,
          level: level,
          // referenceType: incidentsValue,
          referenceType: newArrayIncidents,
          company_id: parseInt(values.company_id, 10)
        };

        isNew
          ? await handleAdd({ values: newValues, helpers })
          : await handleUpdate({ values: newValues, helpers });
      } else {
        showNotify(
          'No se puede agregar más niveles de Departamentos debido a que alcanzó el máximo permitido.'
        );
      }
    }
  });

  const handleInputChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setInput({ ...input, [e.target.name]: value });
  };

  const handleAddItem = () => {
    if (isEditing) {
      const newTodos = [...incidentsValue];
      newTodos[editIndex] = input;
      setIncidentsValue(newTodos);
      setIsEditing(false);
      setEditIndex(null);
    } else {
      setIncidentsValue([...incidentsValue, input]);
    }
    setInput({ reference_type: '' });
  };

  const handleDeleteItem = (index) => {
    const newTodos = [...incidentsValue];
    newTodos.splice(index, 1);
    setIncidentsValue(newTodos);
    setModalOpen(false);
  };

  const handleEditItem = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    setInput(incidentsValue[index]);
  };

  const handleCanceled = () => {
    setIsEditing(false);
    setEditIndex(null);
    setInput({ reference_type: '' });
  };

  const handleOpenCornfirm = (index) => {
    setModalOpen(true);
    setIdDelete(index);
  };

  const renderReferenceType = () => (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <InputField
          select
          label="Tipo de referencia"
          name="reference_type"
          value={input.reference_type}
          onChange={handleInputChange}
          fullWidth
          sx={{ marginBottom: '1em', minWidth: '150px' }}
        >
          {referenceType.map((item) => (
            <MenuItem key={item.code} value={item}>
              {item.name}
            </MenuItem>
          ))}
        </InputField>
        <Button
          size="medium"
          variant="contained"
          onClick={handleAddItem}
          disabled={!input.reference_type}
          startIcon={isEditing ? <CheckIcon /> : <PlusIcon />}
          sx={{
            marginTop: 2,
            maxHeight: '3em'
          }}
        />
        {isEditing && (
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            onClick={handleCanceled}
            startIcon={<XIcon />}
            sx={{
              marginTop: 2,
              maxHeight: '3em'
            }}
          />
        )}
      </Box>
      <Card>
        <Scrollbar>
          <Table sx={{ minWidth: 500, marginTop: 1, marginLeft: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Tipo de Referencia</TableCell>
                <TableCell> &nbsp; </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidentsValue.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.reference_type?.name}</TableCell>
                  <TableCell>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(index)}>
                      <PencilIcon color="secondary" />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleOpenCornfirm(index)}
                    >
                      <TrashIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader title={`${isNew ? 'Agregar' : 'Editar'} Departamento`} />
        <CardContent>
          <InputField
            label="Código"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            sx={{ marginBottom: '1em' }}
          />
          <InputField
            label="Nombre"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ marginBottom: '1em' }}
          />

          {formik.values.level === 1 && isNew && renderReferenceType()}
          {formik.values.level === 2 && !isNew && renderReferenceType()}
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'end',
            padding: '16px'
          }}
        >
          <GoalButton
            color="primary"
            loading={formik.isSubmitting}
            type="submit"
            permission={GadminActions.GCC_TEAM_BRANDS_UP}
          >
            Guardar
          </GoalButton>
          <GoalButton onClick={onClose}>Cancelar</GoalButton>
        </CardActions>
      </form>
      <ConfirmationDialog
        message="Estas seguro de eliminar esta referencia"
        onCancel={() => setModalOpen(false)}
        onConfirm={() => handleDeleteItem(idDelete)}
        open={modalOpen}
        title="Confirmación importante"
        variant="warning"
      />
    </Card>
  );
};

MaintenanceIncidentCategoriesForm.propTypes = {
  isNew: Proptypes.bool,
  initialValues: Proptypes.object,
  configState: Proptypes.object,
  referenceType: Proptypes.array,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func
};
