import GadminActions from '../contexts/gadmin-actions';
import { AllowedGuard } from '../hooks/use-allowed';
import { lazy } from 'react';
import { Loadable } from './loadable';

import { MaintenanceEmployeePosition } from '../containers/master-data/payroll/maintenance-employee-position';
import { MaintenanceDepartments } from '../containers/master-data/payroll/maintenance-departments';
import { MaintenanceTypesOfVauchers } from '../containers/master-data/base/maintenance-types-of-vauchers';
import { MaintenancePaymentTerm } from '../containers/master-data/base/maintenance-payment-terms';
import { MaintenanceIncidentCategories } from '../containers/master-data/incidents/maintenance-incident-categories';
import { AttachmentTypeProvider } from '../contexts/attachments/attachment-type-context';
import { AttachmentTemplateProvider } from '../contexts/attachments/attachment_template-context';

const MaintenanceLineContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-lines-continer').then((module) => ({
      default: module.MaintenanceLineContainer
    }))
  )
);

const MaintenanceCompanies = Loadable(
  lazy(() =>
    import('../containers/master-data/base/maintenance-companies').then((module) => ({
      default: module.MaintenanceCompanies
    }))
  )
);

/** (start) Attachments */
const MaintenanceAttachmentTypes = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/maintenance-attachment-types').then((module) => ({
      default: module.MaintenanceAttachmentTypes
    }))
  )
);

const AttachmentTypeCreate = Loadable(
  lazy(() =>
    import('../components/maintenance/assets/attachment-type-create').then((module) => ({
      default: module.AttachmentTypeCreate
    }))
  )
);

const AttachmentTypeTabs = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-type-tabs').then((module) => ({
      default: module.AttachmentTypeTabs
    }))
  )
);

const AttachmentType = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-type').then((module) => ({
      default: module.AttachmentType
    }))
  )
);

const AttachmentTypeLines = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-type-lines').then((module) => ({
      default: module.AttachmentTypeLines
    }))
  )
);

const MaintenanceAttachmentGroups = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/maintenance-attachment-groups').then((module) => ({
      default: module.MaintenanceAttachmentGroups
    }))
  )
);

const AttachmentGroupCreate = Loadable(
  lazy(() =>
    import('../components/maintenance/assets/attachment-group-create').then((module) => ({
      default: module.AttachmentGroupCreate
    }))
  )
);

const AttachmentGroupTabs = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-group-tabs').then((module) => ({
      default: module.AttachmentGroupTabs
    }))
  )
);

const AttachmentGroup = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-group').then((module) => ({
      default: module.AttachmentGroup
    }))
  )
);

const MaintenanceAttachmentTemplates = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/maintenance-attachment-templates').then((module) => ({
      default: module.MaintenanceAttachmentTemplates
    }))
  )
);

const AttachmentTemplateCreate = Loadable(
  lazy(() =>
    import('../components/maintenance/assets/attachment-template-create').then((module) => ({
      default: module.AttachmentTemplateCreate
    }))
  )
);

const AttachmentTemplateTabs = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-tabs').then((module) => ({
      default: module.AttachmentTemplateTabs
    }))
  )
);

const AttachmentTemplate = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template').then((module) => ({
      default: module.AttachmentTemplate
    }))
  )
);

const AttachmentTemplateGroups = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-groups').then((module) => ({
      default: module.AttachmentTemplateGroups
    }))
  )
);

const AttachmentTemplateGroup = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-group').then((module) => ({
      default: module.AttachmentTemplateGroup
    }))
  )
);

const AttachmentTemplateGroupCreate = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-group-create').then((module) => ({
      default: module.AttachmentTemplateGroupCreate
    }))
  )
);

const AttachmentTemplateLines = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-lines').then((module) => ({
      default: module.AttachmentTemplateLines
    }))
  )
);

const AttachmentTemplateLineCreate = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-line-create').then((module) => ({
      default: module.AttachmentTemplateLineCreate
    }))
  )
);

const AttachmentTemplateLine = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachment-template-line').then((module) => ({
      default: module.AttachmentTemplateLine
    }))
  )
);

// Attachments
const Attachments = Loadable(
  lazy(() =>
    import('../containers/master-data/assets/attachments/attachments').then((module) => ({
      default: module.Attachments
    }))
  )
);

const PaymentTermCreate = Loadable(
  lazy(() =>
    import('../components/maintenance/base/payment-term-create').then((module) => ({
      default: module.PaymentTermCreate
    }))
  )
);

const PaymentTermTabs = Loadable(
  lazy(() =>
    import('../containers/master-data/base/payment-term-tabs').then((module) => ({
      default: module.PaymentTermTabs
    }))
  )
);

const PaymentTerm = Loadable(
  lazy(() =>
    import('../containers/master-data/base/payment-term').then((module) => ({
      default: module.PaymentTerm
    }))
  )
);

/* master-data (begin)*/
const MaintenanceBusinessPartnerGroupsContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-business-partner-groups-container').then(
      (module) => ({
        default: module.MaintenanceBusinessPartnerGroupsContainer
      })
    )
  )
);
const MaintenanceBrandsContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-brands-container').then((module) => ({
      default: module.MaintenanceBrandsContainer
    }))
  )
);

const MaintenanceClassificationsContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-classifications-container').then(
      (module) => ({
        default: module.MaintenanceClassificationsContainer
      })
    )
  )
);

const MaintenanceCategoriesContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-categories-container').then((module) => ({
      default: module.MaintenanceCategoriesContainer
    }))
  )
);

const MaintenanceAttributeGroupsContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-attribute-groups-container').then(
      (module) => ({
        default: module.MaintenanceAttributeGroupsContainer
      })
    )
  )
);

const MaintenanceTerritoriesContainer = Loadable(
  lazy(() =>
    import('../containers/master-data/master/maintenance-territories-container').then((module) => ({
      default: module.MaintenanceTerritoriesContainer
    }))
  )
);

const MaintenanceLineOfBusiness = Loadable(
  lazy(() =>
    import('../containers/master-data/base/maintenance-line-of-business').then((module) => ({
      default: module.MaintenanceLineOfBusiness
    }))
  )
);

const MaintenanceDivisions = Loadable(
  lazy(() =>
    import('../containers/master-data/base/maintenance-divisions').then((module) => ({
      default: module.MaintenanceDivisions
    }))
  )
);

const MaintenanceSalesForceGroups = Loadable(
  lazy(() =>
    import('../containers/master-data/base/maintenance-sales-force-groups').then((module) => ({
      default: module.MaintenanceSalesForceGroups
    }))
  )
);

const SalesTeamGroupCreate = Loadable(
  lazy(() =>
    import('../containers/master-data/base/sales-team-group-create').then((module) => ({
      default: module.SalesTeamGroupCreate
    }))
  )
);

const SalesTeamGroupTabs = Loadable(
  lazy(() =>
    import('../containers/master-data/base/sales-team-group-tabs').then((module) => ({
      default: module.SalesTeamGroupTabs
    }))
  )
);

const SalesTeamGroup = Loadable(
  lazy(() =>
    import('../containers/master-data/base/sales-team-group').then((module) => ({
      default: module.SalesTeamGroup
    }))
  )
);

const MaintenanceBranchs = Loadable(
  lazy(() =>
    import('../containers/master-data/base/maintenance-branchs').then((module) => ({
      default: module.MaintenanceBranchs
    }))
  )
);
/* master-data (end)*/
/* commercial (begin)*/

/* commercial (end)*/

export const MasterDataRoutes = [
  {
    path: 'business-partner-group',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceBusinessPartnerGroupsContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'brand',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceBrandsContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'lines',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceLineContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'classification',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceClassificationsContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'categories',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceCategoriesContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'attribute-groups',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceAttributeGroupsContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'territories',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceTerritoriesContainer />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'line-of-business',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceLineOfBusiness />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'divisions',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceDivisions />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'companies',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceCompanies />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'branchs',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceBranchs />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'employee-position',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceEmployeePosition />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'departments',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.GCC_TEAM_BRANDS}>
            <MaintenanceDepartments />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'types-of-vouchers',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceTypesOfVauchers />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'sales-force-groups',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceSalesForceGroups />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <SalesTeamGroupCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: <SalesTeamGroupTabs />,
        children: [
          {
            path: '',
            element: <SalesTeamGroup />
          }
        ]
      }
    ]
  },
  {
    path: 'payment-terms',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenancePaymentTerm />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <PaymentTermCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <PaymentTermTabs />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: <PaymentTerm />
          }
        ]
      }
    ]
  },
  {
    path: 'incident-categories',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceIncidentCategories />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'attachments',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <Attachments />
          </AllowedGuard>
        )
      }
    ]
  },
  {
    path: 'attachment-types',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceAttachmentTypes />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <AttachmentTypeCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <AttachmentTypeProvider>
              <AttachmentTypeTabs />
            </AttachmentTypeProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: <AttachmentType />
          },
          {
            path: 'lines',
            element: <AttachmentTypeLines />
          }
        ]
      }
    ]
  },
  {
    path: 'attachment-templates',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceAttachmentTemplates />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <AttachmentTemplateCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.G2_BULK_LIST}>
            <AttachmentTemplateProvider>
              <AttachmentTemplateTabs />
            </AttachmentTemplateProvider>
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: <AttachmentTemplate />
          },
          {
            path: 'lines',
            children: [
              {
                path: '',
                element: <AttachmentTemplateLines />
              },
              {
                path: 'create',
                element: <AttachmentTemplateLineCreate />
              },
              {
                path: ':lineId',
                element: <AttachmentTemplateLine />
              }
            ]
          },
          {
            path: 'groups',
            children: [
              {
                path: '',
                element: <AttachmentTemplateGroups />
              },
              {
                path: 'create',
                element: <AttachmentTemplateGroupCreate />
              },
              {
                path: ':groupId',
                element: <AttachmentTemplateGroup />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'attachment-groups',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <MaintenanceAttachmentGroups />
          </AllowedGuard>
        )
      },
      {
        path: 'create',
        element: (
          <AllowedGuard permission={GadminActions.G2_QUESTIONARY_ADD}>
            <AttachmentGroupCreate />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: <AttachmentGroupTabs />,
        children: [
          {
            path: '',
            element: <AttachmentGroup />
          }
        ]
      }
    ]
  }
];
