import Proptypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { RenderIf } from '../../render-if';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import { Star as StarIcon } from '../../../icons/star';
import GadminActions from '../../../contexts/gadmin-actions';

import { deliveryApi } from '../../../api/delivery';
import { useMounted } from '../../../hooks/use-mounted';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { useApp } from '../../../hooks/use-app';
import { NumberColor } from '../../number-color';
import { useDialog } from '../../../hooks/use-dialog';
import { AuxiliaryCurrentEdit } from './auxiliary-edit';
import GoalRecordLink from '../../goal-record-link';

export const AuxiliaryHstTable = (props) => {
  const { id = '-', filterBy } = props;
  const mounted = useMounted();
  const { showNotify } = useApp();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editDialogOpen, handleOpenEditDialog, handleCloseEditDialog] = useDialog();
  const [lineState, setLineState] = useState({});

  const { pathname } = useLocation();
  const handleExitedDialog = () => {};

  const handleEdit = (item) => {
    setLineState(item);
    handleOpenEditDialog(true);
  };
  const [documentLogState, setDocumentLogState] = useState({ isLoading: true, reload: true });
  const [controller, setController] = useState({ reload: true });

  const handleOpenAppendDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleRefresh = () => {
    setController({ ...controller, reload: true });
  };
  const onCancelConfirmationDialog = () => {
    setOpenConfirmDialog(false);
  };

  const processOrderHandler = useCallback(async () => {
    setOpenConfirmDialog(false);
    handleRefresh();
    showNotify('Actualizando...');
  }, []);

  const getDocument = useCallback(async () => {
    if (controller.reload) {
      setDocumentLogState(() => ({ ...documentLogState, isLoading: true }));
      try {
        const result = await deliveryApi.getAuxiliariesHst({ type: filterBy, id });
        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            data: result,
            reload: false
          }));
        }
      } catch (err) {
        console.error(err);

        if (mounted.current) {
          setDocumentLogState(() => ({
            isLoading: false,
            error: err.message,
            real: true
          }));
        }
      }
      controller.reload = false;
    }
  }, [controller, id]);

  useEffect(() => {
    getDocument().catch(console.error);
  }, [controller]);
  const showVehicleHst = pathname?.includes('hst-date');
  const showDateHst = pathname?.includes('hst-vehicle');
  if (!documentLogState.data) {
    return <LoadingContent loadingText="No hay informacion del reparto..." />;
  }
  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Planilla de reparto actual" />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<StarIcon />}
                  onClick={handleOpenAppendDialog}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Chofer J/R</TableCell>
              <TableCell>Zona</TableCell>
              <TableCell>Div</TableCell>
              <TableCell># Aux Planilla</TableCell>
              <TableCell>S/ Costo</TableCell>
              <TableCell># HRs</TableCell>
              <TableCell># Clientes</TableCell>
              <TableCell>S/ Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentLogState?.data?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <GoalRecordLink
                    label={item.id}
                    tooltip={`Editar planilla ${item.id} para ${item.cardname}`}
                    onClick={() => {
                      handleEdit(item);
                    }}
                    editable
                  />
                </TableCell>
                <TableCell>
                  <RenderIf condition={showDateHst} no={moment(item.fecha).format('DD/MM/YYYY')}>
                    <GoalRecordLink
                      to={`/delivery/auxiliary/hst-date/${moment(item.fecha).format('YYYY-MM-DD')}`}
                      label={moment(item.fecha).format('DD/MM/YYYY')}
                      tooltip={`Ver planilla del dia ${moment(item.fecha).format('DD/MM/YYYY')}`}
                    />
                  </RenderIf>
                </TableCell>
                <TableCell sx={{ minWidth: 90 }}>
                  <RenderIf condition={showVehicleHst} no={item.placa}>
                    <GoalRecordLink
                      to={`/delivery/auxiliary/hst-vehicle/${item.placa}`}
                      label={item.placa}
                      tooltip={`Ver planilla del vehiculo ${item.placa}`}
                    />
                  </RenderIf>
                </TableCell>
                <TableCell sx={{ minWidth: 280 }}>
                  <Box>
                    <Typography color="inherit" variant="body2">
                      {item.cardcode}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {item.cardname}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{item.sede}</TableCell>
                <TableCell>{item.tiporeparto}</TableCell>
                <TableCell>
                  <NumberColor value={item.nroauxpla} format="0" />
                </TableCell>
                <TableCell>
                  <NumberColor value={item.pagoxapoyo} />
                </TableCell>
                <TableCell>{item.hojas}</TableCell>
                <TableCell>{item.clientes}</TableCell>
                <TableCell>
                  <NumberColor value={item.soles} format="0,000.00" minValue={5000} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ConfirmationDialog
          message="Esta seguro de autocorregir los Registros"
          onCancel={onCancelConfirmationDialog}
          onConfirm={processOrderHandler}
          open={openConfirmDialog}
          title="Autoreparar sucesos"
          variant="warning"
        />
        <AuxiliaryCurrentEdit
          onClose={handleCloseEditDialog}
          onExited={handleExitedDialog}
          afterSubmit={handleRefresh}
          open={editDialogOpen}
          lineState={lineState}
          refreshLine={setLineState}
          document={document}
        />
      </Scrollbar>
    </Card>
  );
};

AuxiliaryHstTable.propTypes = {
  filterBy: Proptypes.string.isRequired,
  id: Proptypes.string
};
