import Proptypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Switch,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { goalMaintenancePayrollApi } from '../../../api/goal/maintenance/goal-maintenance-payroll';
import GadminActions from '../../../contexts/gadmin-actions';
import { InputField } from '../../input-field';
import { useApp } from '../../../hooks/use-app';
import { CompanyInputDisabled } from '../../common/company-input-disabled';
import { ConstantStatus, ConstantYesNo } from '../../../api/application-enums';
import GoalButton from '../../goal-button';
import { useFormSaver } from '../../../hooks/use-form-saver';

export const MaintenanceEmployeePositionForm = ({
  isNew = true,
  initialValues,
  configState = {},
  onClose,
  departamentState = [],
  handleRefresh
}) => {
  const { currentCompany, showNotify, showError } = useApp();

  const [handleUpdate, handleAdd] = useFormSaver({
    originalData: initialValues,
    apiUpdateHandler: goalMaintenancePayrollApi.updateEmployeePositionsForm,
    apiAddHandler: goalMaintenancePayrollApi.postEmployeePositionsForm,
    id: 'id',
    includeNewValues: true,
    handleAfterSave: () => {
      formik.resetForm();
      onClose();
      handleRefresh();
    },
    mandatoryFields: ['company_id']
  });

  const formik = useFormik({
    initialValues: {
      id: initialValues?.id || '',
      company_id: initialValues?.company_id || currentCompany,
      code: initialValues?.code || '',
      name: initialValues?.name || '',
      depends_on_id: initialValues?.depends_on_id || null,
      for_sale: (initialValues?.for_sale || ConstantYesNo.NO) === ConstantYesNo.YES,
      parent_id: initialValues?.parent_id || null,
      level: initialValues?.level || '1',
      department_id: initialValues?.department_id || null,
      number_of_employees: initialValues?.number_of_employees || 0,
      number_of_vacancies: initialValues?.number_of_vacancies || 0,
      number_of_employees_required: initialValues?.number_of_employees_required || 0,
      status: initialValues?.status || ConstantStatus.ACTIVE
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es obligatorio'),
      code: Yup.string().required('Este campo es obligatorio')
    }),
    onSubmit: async (values, helpers) => {
      if (!configState.success) {
        showError(configState?.message);
        return;
      }

      const for_sale = values.for_sale ? ConstantYesNo.YES : ConstantYesNo.NO;
      const level = values.level ?? 1;
      if (
        (configState?.data['max-level'] && configState?.data['max-level'] > parseInt(level, 10)) ||
        configState?.data['max-level'] === -1
      ) {
        const newValues = {
          ...values,
          for_sale: for_sale,
          level: level,
          company_id: parseInt(values.company_id, 10)
        };

        isNew
          ? await handleAdd({ values: newValues, helpers })
          : await handleUpdate({ values: newValues, helpers });
      } else {
        showNotify(
          'No se puede agregar más niveles de puestos de empleado debido a que alcanzó el máximo permitido.'
        );
      }
    }
  });

  return (
    <Card>
      <form onSubmit={formik.handleSubmit}>
        <CardHeader title={`${isNew ? 'Agregar' : 'Editar'} puesto de empleado`} />
        <CardContent>
          <InputField
            label="Código"
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            sx={{ marginBottom: '1em' }}
          />
          <CompanyInputDisabled currentCompany={currentCompany} />
          <InputField
            label="Nombre"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ marginBottom: '1em' }}
          />
          <InputField
            select
            label="Departamento"
            name="department_id"
            value={formik.values.department_id}
            fullWidth
            onChange={formik.handleChange}
            sx={{ marginBottom: 2 }}
            error={formik.touched.department_id && Boolean(formik.errors.department_id)}
            helperText={formik.touched.department_id && formik.errors.department_id}
          >
            {departamentState.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </InputField>
          <Grid>
            <Typography variant="caption" sx={{ fontWeight: 500 }}>
              Venta
            </Typography>
            <Switch
              checked={formik.values.for_sale}
              onChange={formik.handleChange}
              name="for_sale"
              color="primary"
            />
          </Grid>
          <InputField
            label="Número de empleados"
            name="number_of_employees"
            value={formik.values.number_of_employees}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.number_of_employees && Boolean(formik.errors.number_of_employees)}
            helperText={formik.touched.number_of_employees && formik.errors.number_of_employees}
            sx={{ marginBottom: '1em' }}
          />
          <InputField
            label="Número de vacantes"
            name="number_of_vacancies"
            value={formik.values.number_of_vacancies}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.number_of_vacancies && Boolean(formik.errors.number_of_vacancies)}
            helperText={formik.touched.number_of_vacancies && formik.errors.number_of_vacancies}
            sx={{ marginBottom: '1em' }}
          />
          <InputField
            label="Número de empleados requerido"
            name="number_of_employees_required"
            value={formik.values.number_of_employees_required}
            onChange={formik.handleChange}
            fullWidth
            error={
              formik.touched.number_of_employees_required &&
              Boolean(formik.errors.number_of_employees_required)
            }
            helperText={
              formik.touched.number_of_employees_required &&
              formik.errors.number_of_employees_required
            }
            sx={{ marginBottom: '1em' }}
          />
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'end',
            padding: '16px'
          }}
        >
          <GoalButton
            color="primary"
            loading={formik.isSubmitting}
            type="submit"
            permission={GadminActions.G2_CONTESTS_ADD}
          >
            Guardar
          </GoalButton>
          <GoalButton onClick={onClose}>Cancelar</GoalButton>
        </CardActions>
      </form>
    </Card>
  );
};

MaintenanceEmployeePositionForm.propTypes = {
  isNew: Proptypes.bool,
  initialValues: Proptypes.object,
  configState: Proptypes.object,
  departamentState: Proptypes.array,
  onClose: Proptypes.func,
  handleRefresh: Proptypes.func
};
