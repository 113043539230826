import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './pdf-native-viewer.css'; // Import the CSS file

const PdfNativeViewer = ({ content, fileName }) => {
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (content) {
      const byteCharacters = atob(content);
      const byteNumbers = new Array(byteCharacters.length)
        .fill()
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [content]);

  return (
    <div className="pdf-container">
      {pdfUrl ? (
        <iframe src={pdfUrl} className="pdf-iframe" title={fileName || 'PDF Viewer'} />
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

PdfNativeViewer.propTypes = {
  content: PropTypes.string,
  fileName: PropTypes.string
};

export default PdfNativeViewer;
