import { dataLoader } from '../../../data-loader';

const endpointLocal = 'accounting/sunat/sire-purchases';

class SirePurchasesApi {
  async getPeriods({ companyId }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/periods`,
      params: { companyId }
    });
    return response;
  }

  async listProposal({ companyId, perTributario }) {
    const response = await dataLoader.goalGet({
      action: `${endpointLocal}/list-proposal`,
      params: { companyId, perTributario }
    });
    return response;
  }

  async synchronizeProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/synchronize-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }

  async synchronizeSummaryOfRceProposal({ companyId, perTributario }) {
    const response = await dataLoader.loadGoalPost({
      action: `${endpointLocal}/sync-summary-rce-proposal`,
      changes: { company_id: companyId, perTributario }
    });
    return response;
  }
}

export const sirePurchasesApi = new SirePurchasesApi();
