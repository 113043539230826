import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { useData } from '../../../hooks/use-data';
import { useGoalTheme } from '../../../hooks/use-goal-theme';
import { AddButton, RefreshButton, SyncButton } from '../../../components/common/buttons';
import { MaintenanceDetailDefault } from '../../../components/maintenance/maintenance-detail-default';
import GoalCustomDialog from '../../../components/goal-custom-dialog';
import { MaintenanceTypesOfVauchersForm } from '../../../components/maintenance/base/maintenance-types-of-vauchers-form';
import {
  getReceiptTypesWithChildren,
  syncReceiptTypes
} from '../../../api/goal/base/common/receipt-type';
import { CommonRichTreeView } from '../../../components/common/tree-view/common-rich-tree-view';
import LoadingContent from '../../../components/common/loading-content';

export const MaintenanceTypesOfVauchers = () => {
  const { title, companyState, currentCompany, showNotify, showError } = useApp();
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [open, setOpen] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [selectedNodeDataItem, setSelectedNodeDataItem] = useState(null);

  const [receiptTypesState, receiptTypesRefresh] = useData({
    sourceApi: getReceiptTypesWithChildren,
    apiParameter: { companyId: currentCompany },
    loadingMessage: 'Cargando tipos de comprobantes',
    mounted
  });

  const handleRefresh = () => {
    receiptTypesRefresh({ companyId: currentCompany });
  };

  const handleClose = () => {
    setEditmode(false);
    setOpen(false);
  };

  const onNodeSelection = (item) => {
    setSelectedNodeDataItem(item);
  };

  const handleSync = async () => {
    try {
      const result = await syncReceiptTypes({ companyId: currentCompany });
      if (result.success) {
        showNotify(result.message);
        handleRefresh();
      } else {
        showError(result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const loadTreeRoot = async () => {
      receiptTypesRefresh({ companyId: currentCompany });
    };
    loadTreeRoot();
  }, [currentCompany]);

  const renderContent = () => {
    if (receiptTypesState.isLoading) {
      return (
        <LoadingContent
          title="Tipos de comprobantes"
          message="Cargando tipos de comprobantes..."
          state={receiptTypesState}
        />
      );
    }
    return (
      <Card sx={{ p: 3 }}>
        <Box>
          <Grid container spacing={8} sx={{ mb: 2 }}>
            <Grid item md={6} xs={12}>
              <CommonRichTreeView
                title="Tipo de comprobantes..."
                onSelectNode={onNodeSelection}
                setSelectedNodeDataItem={setSelectedNodeDataItem}
                items={receiptTypesState.data}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MaintenanceDetailDefault
                company={companyState?.data}
                onEdit={() => {
                  setEditmode(true);
                  setOpen(true);
                }}
                item={selectedNodeDataItem}
                btnEdit={selectedNodeDataItem != null}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title('Tipos de comprobantes')} </title>
      </Helmet>
      <Box sx={{ backgroundColor: 'background.default', flexGrow: 1 }}>
        <Container
          maxWidth="false"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            pb: 5
          }}
        >
          <Box sx={{ py: 4 }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="h4">
                Tipos de comprobantes
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <SyncButton onClick={handleSync} />
              <AddButton onClick={() => setOpen(true)} permission={GadminActions.GCC_TDOY_LIST} />
              <RefreshButton onClick={handleRefresh} permission={GadminActions.GCC_TDOY_LIST} />
            </Box>
          </Box>
          {renderContent()}

          <GoalCustomDialog
            open={open}
            handleClose={handleClose}
            maxWidth="xs"
            fullScreen={isMobile}
            withoutFooter
          >
            <MaintenanceTypesOfVauchersForm
              isNew={!editMode}
              onClose={handleClose}
              // configState={configState.isLoading ? {} : configState}
              configState={{}}
              handleRefresh={handleRefresh}
              initialValues={editMode ? selectedNodeDataItem : null}
            />
          </GoalCustomDialog>
        </Container>
      </Box>
    </>
  );
};
