import { AllowedGuard } from '../hooks/use-allowed';
import GadminActions from '../contexts/gadmin-actions';
import { Loadable } from './loadable';
import { lazy } from 'react';

const IncidentsContainer = Loadable(
  lazy(() =>
    import('../containers/incidents/incidents-container').then((module) => ({
      default: module.IncidentsContainer
    }))
  )
);

const IncidentsCreate = Loadable(
  lazy(() =>
    import('../containers/incidents/incidents-create').then((module) => ({
      default: module.IncidentsCreate
    }))
  )
);

const IncidentsTab = Loadable(
  lazy(() =>
    import('../containers/incidents/incidents-tab').then((module) => ({
      default: module.IncidentsTab
    }))
  )
);

const IncidentsDetailContainer = Loadable(
  lazy(() =>
    import('../containers/incidents/incidents-detail-container').then((module) => ({
      default: module.IncidentsDetailContainer
    }))
  )
);

const IncidentsTransactionsContainer = Loadable(
  lazy(() =>
    import('../containers/incidents/incidents-transactions-container').then((module) => ({
      default: module.IncidentsTransactionsContainer
    }))
  )
);

export const IncidentRoutes = [
  {
    path: 'incidents',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
            <IncidentsContainer />
          </AllowedGuard>
        )
      },
      {
        path: ':id',
        element: (
          <AllowedGuard permission={GadminActions.GCC_TDOY_DET}>
            <IncidentsTab />
          </AllowedGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_SUMARY}>
                <IncidentsDetailContainer />
              </AllowedGuard>
            )
          },
          {
            path: 'transactions',
            element: (
              <AllowedGuard permission={GadminActions.GCC_TDOY_SUMARY}>
                <IncidentsTransactionsContainer />
              </AllowedGuard>
            )
          }
        ]
      }
    ]
  },
  {
    path: 'create-incidents',
    children: [
      {
        path: '',
        element: (
          <AllowedGuard permission={GadminActions.G2_SURVEY_LIST}>
            <IncidentsCreate />
          </AllowedGuard>
        )
      }
    ]
  }
];
