import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
import { cacheEnabled } from './app-definitions';
import { applyFilters } from '../utils/apply-filters';
import { applyPagination } from '../utils/apply-pagination';
import { applySort } from '../utils/apply-sort';

/* eslint class-methods-use-this:0 */

class DeliveryVehicleApi {
  async getHRInvoicing() {
    const response = await GadminRequest(GadminActions.GCC_OPEN_ORDERS, {
      type: 'list'
    });
    return Promise.resolve(response.data);
  }

  async getDeliveryVehicles(options) {
    const { filters, sort, sortBy, page, query, view, reload, apiData } = options;
    const cacheItem = 'delivery-vehicles';
    let returnsData = null;

    if (reload) {
      if (cacheEnabled) {
        returnsData = JSON.parse(localStorage.getItem(cacheItem)) || [];
      }

      if (!returnsData) {
        const response = await GadminRequest(GadminActions.GCC_DELIVERY_LIST_VEH, {
          type: 'list',
          payload: { filters, sort, sortBy, page, query, view }
        });
        const { data } = response;
        returnsData = data;
        if (cacheEnabled) {
          localStorage.setItem(cacheItem, JSON.stringify(returnsData));
        }
      }
    } else {
      returnsData = apiData;
    }

    if (!returnsData || !Array.isArray(returnsData))
      return {
        documents: [],
        documentsCount: 0
      };

    const queriedInvoices = returnsData.filter((_invoice) => {
      // If query exists, it looks only in customer id field
      if (
        !!query &&
        !(
          _invoice.Code?.toString().toLowerCase().includes(query.toLowerCase()) ||
          _invoice.vehicleEm?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.vehicleEmName?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.vehicleJr?.toLowerCase().includes(query.toLowerCase()) ||
          _invoice.vehicleJrName?.toLowerCase().includes(query.toLowerCase())
        )
      ) {
        return false;
      }

      // No need to look for any resource fields
      if (typeof view === 'undefined' || view === 'all') {
        return true;
      }
      // In this case, the view represents the resource status
      return _invoice.sede === view;
    });
    const filteredInvoices = applyFilters(queriedInvoices, filters);
    const sortedInvoices = applySort(filteredInvoices, sort, sortBy);
    const paginatedInvoices = applyPagination(sortedInvoices, page);

    return {
      documents: paginatedInvoices,
      documentsCount: filteredInvoices.length,
      apiData: returnsData
    };
  }

  async bulkDeliveryVehiclesActions(props) {
    const response = await GadminRequest(GadminActions.GCC_DELIVERY_VEH_UP, props);
    return response;
  }
}

export const deliveryVehicleApi = new DeliveryVehicleApi();
