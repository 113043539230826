// import PropTypes from 'prop-types';

import AutoModeIcon from '@mui/icons-material/AutoMode';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import RepartitionIcon from '@mui/icons-material/Repartition';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SyncIcon from '@mui/icons-material/Sync';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import CloseIcon from '@mui/icons-material/Close';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useNavigate } from 'react-router-dom';

import { GoalMenu } from '../../common/goal-menu';
import { cancelBulkSales } from '../../../api/goal/sync/goal-sync-master';
import useProcesor from '../../../hooks/use-procesor';
import { goalGprocApi } from '../../../api/goal/core/goal-gproc';
import GoalMenuItem from '../../../components/common/goal-menu-item';

import { useApp } from '../../../hooks/use-app';
import GadminActions from '../../../contexts/gadmin-actions';
import { ConfirmationMenuItem } from '../../../components/common/confirmation-menu-item';
import { useAuth } from '../../../hooks/use-auth';

export const GoalManagementMenu = () => {
  const { appUrlBase, currentCompany, showNotify, showError } = useApp();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();

  const { procesor: handleRefreshAdObjects } = useProcesor({
    handler: goalGprocApi.refreshActiveDirectoryObjects
  });
  const { procesor: handleRefreshProductPrices } = useProcesor({
    handler: goalGprocApi.updatePricesAsJob
  });

  const { procesor: handleCancelBulkSales } = useProcesor({
    handler: cancelBulkSales
  });
  const { procesor: handleReindexCustomers } = useProcesor({
    handler: goalGprocApi.reindexDatabase
  });
  const { procesor: handleQueueAllNcs } = useProcesor({
    handler: goalGprocApi.queueAllNcs
  });
  const { procesor: handleCleanPortfolioCustomers } = useProcesor({
    handler: goalGprocApi.cleanPortfolioCustomers
  });
  const { procesor: handleRefreshPortfolioCustomers } = useProcesor({
    handler: goalGprocApi.refreshPortfolioCustomers
  });

  const { procesor: handleGrantAllPermissions } = useProcesor({
    handler: goalGprocApi.grantAllPermissions
  });
  const { procesor: handleRefreshTVendoConfig } = useProcesor({
    handler: goalGprocApi.refreshTVendoConfig
  });

  const canReset = hasPermission(GadminActions.GCC_GPROC_MNGMT);

  return (
    <GoalMenu label="Acciones">
      <GoalMenuItem
        startIcon={<SyncAltIcon />}
        helpText={'Ver los ultimos eventos'}
        onClick={async () => {
          const link = `${appUrlBase}/logs/gadmin-logs`;
          navigate(link);
        }}
        label={'Sucesos'}
      />
      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<AutoModeIcon />}
          helpText={'Reindex base de datos de clientes'}
          onConfirm={async () => {
            await handleReindexCustomers({
              companyId: currentCompany,
              module: 'customers'
            });
          }}
          label={'Reindex Database'}
          confirmationMessage={'Está seguro que desea reindexar la base de datos'}
        />
      )}
      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<WorkspacePremiumIcon />}
          helpText={'Asignar todos los permisos'}
          onConfirm={async () => {
            await handleGrantAllPermissions({ companyId: currentCompany });
          }}
          label={'Asignar Permisos'}
          confirmationMessage={'Está seguro que desea asignar todos los permisos'}
        />
      )}
      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<RepartitionIcon />}
          helpText={'Procesar todas las NCs'}
          onConfirm={async () => {
            await handleQueueAllNcs({ companyId: currentCompany });
          }}
          label={'Procesar NCs'}
          confirmationMessage={'Está seguro que desea procesar todas las NCs'}
        />
      )}
      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<CleaningServicesIcon />}
          helpText={'Limpiar cartera de clientes'}
          onConfirm={async () => {
            await handleCleanPortfolioCustomers({ companyId: currentCompany });
          }}
          label={'Limpiar Cartera'}
          confirmationMessage={'Está seguro que desea limpiar la cartera de clientes'}
        />
      )}
      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<SyncIcon />}
          helpText={'Refrescar cartera'}
          onConfirm={async () => {
            await handleRefreshPortfolioCustomers({ companyId: currentCompany });
          }}
          label={'Refrescar cartera'}
          confirmationMessage={'Está seguro que desea refrescar la cartera de clientes'}
        />
      )}

      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<CloseIcon />}
          helpText={'Anular KPIs'}
          onConfirm={handleCancelBulkSales}
          label={'Anular KPIs'}
          confirmationMessage={'Está seguro que desea anular los KPIs'}
        />
      )}

      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<SyncLockIcon />}
          helpText={'Actualizar objetos de AD'}
          onConfirm={async () => {
            await handleRefreshAdObjects({ companyId: currentCompany });
          }}
          label={'Actualizar AD'}
          confirmationMessage={'Está seguro que desea actualizar los objetos de AD'}
        />
      )}

      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<QueryStatsIcon />}
          helpText={'Actualizar precios de productos'}
          onConfirm={async () => {
            await handleRefreshProductPrices({ companyId: currentCompany });
          }}
          label={'Actualizar precios'}
          confirmationMessage={'Está seguro que desea actualizar los precios de los productos'}
        />
      )}

      {hasPermission(GadminActions.GCC_GPROC_MNGMT) && (
        <ConfirmationMenuItem
          startIcon={<WorkspacePremiumIcon />}
          helpText={'Actualizar configuración de TVendo'}
          onConfirm={async () => {
            await handleRefreshTVendoConfig({ companyId: currentCompany });
          }}
          label={'Actualizar TVendo'}
          confirmationMessage={'Está seguro que desea actualizar la configuración de TVendo'}
        />
      )}
      {canReset && (
        <ConfirmationMenuItem
          startIcon={<WorkspacePremiumIcon />}
          helpText={'Eliminar cache'}
          onConfirm={async () => {
            const result = await goalGprocApi.resetCache({ companyId: currentCompany });
            if (result?.susccess) {
              showError('Cache eliminado correctamente');
            } else {
              showNotify(result?.message || 'Eliminación de cache finalizada');
            }
          }}
          label={'Eliminar cache'}
          confirmationMessage={'Está seguro que desea eliminar el cache'}
        />
      )}
    </GoalMenu>
  );
};

GoalManagementMenu.propTypes = {};
