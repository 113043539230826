import Axios from 'axios';
// import { Base64 } from 'js-base64';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import { v4 as uuid } from 'uuid';
import { appDefinitions } from '../api/app-definitions';
import GadminActions from '../contexts/gadmin-actions';

// Date.prototype.toJSON = function(){ return moment(this).format(); }
const endPoint = process.env.REACT_APP_API_URL;
const apiCientId = process.env.REACT_APP_API_CLIENT_ID;
const apiClientSecret = process.env.REACT_APP_API_CLIENT_SECRET;
const stage = process.env.REACT_APP_STAGE;
const TOKEN_KEY = `${stage}_${process.env.REACT_APP_API_TOKEN_KEY}`;
const TOKEN_KEY_GOAL = `${stage}_${process.env.REACT_APP_API_TOKEN_KEY_GOAL}`;
const TOKEN_KEY_KC = `${stage}_${process.env.REACT_APP_API_TOKEN_KEY_KC}`;
const GOAL_KEY = `${stage}_GOAL_SID`;
const loginPath = process.env.REACT_APP_API_LOGIN_PATH;
const debugMode = false; // process.env.REACT_APP_DEBUG_MODE == 'true';
const appName = process.env.REACT_APP_NAME;
const appCompany = process.env.REACT_APP_COMPANY;
const gadminClientId = process.env.REACT_APP_GADMIN_CLIENT_ID;
const gadminClientSecret = process.env.REACT_APP_GADMIN_CLIENT_SECRET;
// Guardamos el toke

const writeLog = (loggable) => {
  if (debugMode) console.log(loggable);
};
function setLocalToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

function setLocalTokenGoal(token) {
  localStorage.setItem(TOKEN_KEY_GOAL, token);
}
function setLocalTokenKeycloak(token) {
  localStorage.setItem(TOKEN_KEY_KC, token);
}
function setLocalUser(user) {
  localStorage.setItem('user', user);
}
function getLocalUser() {
  return localStorage.getItem('user');
}

function getLocalToken() {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token || token == 'undefined') {
    return null;
  }
  return token;
}

const setLocalSid = (token) => {
  localStorage.setItem(GOAL_KEY, token);
};

const getLocalSid = () => {
  const localSid = localStorage.getItem(GOAL_KEY);
  if (!localSid) {
    const newId = uuid();
    setLocalSid(newId);
    return newId;
  }
  return localSid;
};

const getGoalKey = () => GOAL_KEY;

// eliminar token de localstorage
function deleteToken() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY_GOAL);
  localStorage.removeItem(TOKEN_KEY_KC);
  localStorage.removeItem('user');
  localStorage.removeItem('GADMIN2_SID');
  localStorage.removeItem('GADMIN_TOKEN');
  localStorage.removeItem('GOAL_TOKEN');
  localStorage.removeItem('GC_TOKEN');
  localStorage.removeItem('TD');
  localStorage.removeItem('TDG');
  localStorage.removeItem('TDGC');
}

const wsInit = ({ user, initializeInterceptors = false }) => {
  Axios.interceptors.request.use((config) => {
    const token = getLocalToken();
    if (token) {
      config.headers = { 'X-Custom-Header': user, Authorization: `Bearer ${token}` };
    } else {
      delete Axios.defaults.headers.common.Authorization;
    }
    return config;
  });
  if (initializeInterceptors) {
    Axios.interceptors.response.use(
      (response) => {
        const { data: responseData } = response;
        return {
          code: responseData.code || 200,
          success: responseData.success,
          data: responseData.success ? responseData.data : null,
          message: responseData.data?.message || responseData.message || 'Satisfactorio',
          error: !responseData.success
        };
      },
      (err) => {
        const errorResponse = {
          code: 400,
          success: false,
          data: null,
          message: 'Error de coumnicación con el servidor',
          error: true
        };
        if (err.isAxiosError) {
          if (err.response) {
            return {
              ...errorResponse,
              data: {
                ...err.response.data,
                message: err.response.data?.Error || err.response.data?.message,
                error: true
              },
              code: err.response.status,
              message: err.response.data?.Error || err.response.data?.message
            };
          }
          // console.error('[Network Error]: No Response Received At');
          return {
            ...errorResponse,
            message: 'No se ha establecido comunicación con el servidor',
            debugMesage: '[Network Error]: No Response Received At'
          };
        }
        // console.error('[Non-HTTP Error]:', err);
        return { ...errorResponse, message: '[Non-HTTP Error]:' };
      }
    );
  }
};

function getCustomUrl(path) {
  const baseUrl = `${endPoint}${path}`;
  return baseUrl;
}

async function getCurrentUser() {
  return GadminRequest(GadminActions.G2_USER_CURRENT, {});
}

async function getClientToken(data) {
  try {
    const response = await Axios.post(getCustomUrl(loginPath), {
      ...data,
      access_token: data.access_token,
      grant_type: 'password',
      client_id: apiCientId,
      client_secret: apiClientSecret
    });
    const tokenRequest = await response.data;
    setLocalToken(tokenRequest.access_token);
    return { ...tokenRequest, success: true };
  } catch (error) {
    const errorInfo = catchError(error);
    console.error(errorInfo);
    return {
      ...errorInfo,
      message: 'No se pudo validar las credenciales'
    };
  }
}
/* Request header field access-control-allow-origin is not allowed by Access-Control-Allow-Headers in preflight response.
 */

async function GadminLogin(data) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const deviceName = `${browserName}-${fullBrowserVersion}`;
  const localUser = getLocalUser();
  const response = await Axios.post(
    getCustomUrl(loginPath),
    {
      Action: GadminActions.G2_TOKEN,
      SWKey: appName,
      DeviceData: { id: data.localSid, name: deviceName, user: localUser, appName },
      Company: appCompany,
      DeviceID: data.localSid,
      Data: {
        Username: data.email,
        Password: data.password,
        LocalSid: data.localSid
      }
    },
    {
      headers
    }
  );
  return response;
}

async function GadminLoginKeycloak(data) {
  const headers = {
    'Content-Type': 'application/json'
  };
  const deviceName = `${browserName}-${fullBrowserVersion}`;
  const localUser = getLocalUser();
  const response = await Axios.post(
    getCustomUrl(loginPath),
    {
      Action: GadminActions.GCC_JWT_LOGIN_KEYCLOAK,
      SWKey: appName,
      DeviceData: { id: data.localSid, name: deviceName, user: localUser, appName },
      Company: appCompany,
      DeviceID: data.localSid,
      Data: {
        Username: data.email,
        Password: data.password,
        LocalSid: data.localSid
      },
      keycloak_token: data.keycloak_token,
      client_id: gadminClientId,
      client_secret: gadminClientSecret
    },
    {
      headers
    }
  );
  return { ...response, ...response.data };
}

async function GadminRequest(action, data) {
  try {
    const deviceName = `${browserName}-${fullBrowserVersion}`;
    const localUser = getLocalUser();
    const localId = getLocalSid();
    const response = await Axios.post(getCustomUrl(loginPath), {
      Action: action,
      SWKey: appDefinitions.name,
      DeviceData: { id: localId, name: deviceName, user: localUser, appName: appDefinitions.name },
      Company: appCompany,
      DeviceID: data.localSid,
      Data: data
      // Attachments: data
    });
    return response;
  } catch (error) {
    console.error(error);
    const errorMsg = error.response?.data?.Error;
    throw new Error(errorMsg);
  }
}
async function GadminFileUpload(action, data, actionSubType = 'load') {
  try {
    const deviceName = `${browserName}-${fullBrowserVersion}`;
    const localUser = getLocalUser();
    const localId = getLocalSid();
    const formData = new FormData();

    formData.append('Action', action);
    formData.append('SWKey', appName);
    formData.append(
      'Data',
      JSON.stringify({
        type: actionSubType,
        payload: data.files.map((file) => file.name)
      })
    );
    formData.append(
      'DeviceData',
      JSON.stringify({
        id: localId,
        name: deviceName,
        user: localUser,
        appName
      })
    );
    formData.append('Company', appCompany);
    formData.append('DeviceID', data.localSid);
    data.files.forEach((file) => {
      formData.append(file.name, file);
    });
    const response = await Axios.post(getCustomUrl(loginPath), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

function wsLogout() {
  deleteToken();
}

function catchError(remoteError) {
  console.error(remoteError);
  try {
    if (!remoteError.response) {
      return {
        success: false,
        message: 'Network Error',
        data: [],
        statusCode: 400
      };
    }
    switch (remoteError.response.status) {
      case 400:
      case 401:
      case 200:
        return {
          success: false,
          message: remoteError.response.data.message,
          data: remoteError.response.data.data,
          statusCode: remoteError.response.status
        };
      default:
        return {
          success: false,
          message: `Error desconocido ${remoteError.response.data.message}`,
          data: remoteError.response.data.data,
          statusCode: remoteError.response.status
        };
    }
  } catch (error) {
    return {
      success: false,
      message: 'Error desconocido',
      data: [],
      statusCode: -1
    };
  }
}

const toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  // const byteCharacters = Base64.decode(b64Data);
  const byteCharacters = atob(b64Data);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export {
  wsInit,
  getLocalToken,
  setLocalToken,
  wsLogout,
  getClientToken,
  getCurrentUser,
  GadminLogin,
  getLocalSid,
  setLocalSid,
  GadminRequest,
  getLocalUser,
  GadminFileUpload,
  toBlob,
  deleteToken,
  setLocalUser,
  writeLog,
  GadminLoginKeycloak,
  setLocalTokenGoal,
  setLocalTokenKeycloak,
  getGoalKey
};
