import { dataLoader } from '../../data-loader';

const endpointLocal = 'payroll/master/departaments';

class DepartamentsApi {
  async getDepartamentsSimpleList({ companyId, notIn = [] }) {
    const notInString = JSON.stringify(notIn);
    const response = await dataLoader.loadGoalGetData({
      action: `${endpointLocal}/simple-list`,
      params: { companyId, notIn: notInString }
    });
    return response;
  }
}

export const departamentsApi = new DepartamentsApi();
