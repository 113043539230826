import GadminActions from '../contexts/gadmin-actions';
import { GadminRequest } from '../services/gadmin';
/* eslint class-methods-use-this:0 */

class DashboardApi {
  async getOrdersStaticOpenBySede() {
    const response = await GadminRequest(GadminActions.GCC_PROC_LOAD_CIERRE_INVENTORY, {
      type: 'pv-open-sede'
    });

    return response;
  }

  async getOrdersPendings() {
    const response = await GadminRequest(GadminActions.GCC_PROC_LOAD_CIERRE_INVENTORY, {
      type: 'pv-open'
    });
    return response;
  }

  async getCustomerStatics() {
    const response = await GadminRequest(GadminActions.GCC_PROC_LOAD_CIERRE_INVENTORY, {
      type: 'customer-statics'
    });
    return response;
  }

  async getProductsStatics() {
    const response = await GadminRequest(GadminActions.GCC_PROC_LOAD_CIERRE_INVENTORY, {
      type: 'products-statics'
    });
    return response;
  }

  async getPromoStatics() {
    const response = await GadminRequest(GadminActions.GCC_PROC_LOAD_CIERRE_INVENTORY, {
      type: 'promo-statics'
    });
    return response;
  }
}

export const dashboardApi = new DashboardApi();
