import PropTypes from 'prop-types';
import { Box, Button, CardHeader } from '@mui/material';
import { Link } from 'react-router-dom';
import { AllowedGuard } from '../../hooks/use-allowed';
import GadminActions from '../../contexts/gadmin-actions';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { Query } from '../../components/query';
import { Plus as PlusIcon } from '../../icons/plus';
import { useApp } from '../../hooks/use-app';

export const QueryCustomerModal = ({
  disabled,
  onQueryChange,
  query,
  handleRefresh,
  title = 'Seleccionar excluyentes',
  btnCreate = false
}) => {
  const { appUrlBase } = useApp();

  return (
    <>
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title={title} sx={{ minWidth: 800 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {!btnCreate && (
              <AllowedGuard permission={GadminActions.GCC_TDOY_LIST}>
                <Link to={`${appUrlBase}/survey/create`}>
                  <Button
                    color="primary"
                    size="medium"
                    startIcon={<PlusIcon />}
                    variant="contained"
                    sx={{
                      marginRight: 1
                    }}
                  />
                </Link>
              </AllowedGuard>
            )}

            <AllowedGuard permission={GadminActions.G2_QUESTIONARY_LIST}>
              <Button
                color="primary"
                size="medium"
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
                variant="contained"
              />
            </AllowedGuard>
          </Box>
        </Box>
      </Box>
      <Query
        disabled={disabled}
        onChange={onQueryChange}
        sx={{
          order: {
            sm: 2,
            xs: 1
          }
        }}
        value={query}
      />
    </>
  );
};

QueryCustomerModal.propTypes = {
  disabled: PropTypes.bool,
  onQueryChange: PropTypes.func,
  query: PropTypes.string,
  handleRefresh: PropTypes.func,
  title: PropTypes.string,
  btnCreate: PropTypes.bool
};
