import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  FormHelperText,
  Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import { InputField } from '../../input-field';
import { AllowedGuard } from '../../../hooks/use-allowed';
import GadminActions from '../../../contexts/gadmin-actions';
import { compareRecordChanged } from '../../../hooks/use-item-change';
import { deliveryApi } from '../../../api/delivery';
import { GoalButton } from '../../goal-button';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useApp } from '../../../hooks/use-app';

export const AuxiliaryCurrentEdit = (props) => {
  const { open, onClose, onExited, afterSubmit, lineState, refreshLine, ...other } = props;
  const { showNotify } = useApp();
  const handleChanges = async (values) => {
    let saved = { success: false };
    try {
      const changes = compareRecordChanged(lineState, values);
      await deliveryApi.upAuxiliaryCurrent({
        id: lineState.id,
        ...changes
      });
      refreshLine({ ...lineState, ...changes });
      saved = { ...saved, success: true };
    } catch (error) {
      saved = { ...saved, error };
    }
    return saved;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: lineState?.id || -1,
      nroauxpla: lineState?.nroauxpla || 0,
      pagoxapoyo: lineState?.pagoxapoyo || 0,
      comments: lineState?.comments || '',
      submit: null
    },
    validationSchema: Yup.object().shape({
      comments: Yup.string().min(10, 'Mínimo 10 characteres').max(255),
      nroauxpla: Yup.number()
        .required('Mensage es requerido')
        .positive('Solo números positivos')
        .integer()
        .min(0)
        .max(lineState?.maxauxplae || 10),
      pagoxapoyo: Yup.number()
        .required('Mensage es requerido')
        .positive('Solo números positivos')
        .integer()
        .min(0)
        .max(100)
    }),
    onSubmit: async (values, helpers) => {
      try {
        const saved = await handleChanges(values);
        if (!saved.success) throw new Error(saved.error);
        showNotify(`Planilla actualizada`);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        afterSubmit();
        onClose();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 500
        }
      }}
      TransitionProps={{
        onExited: () => {
          onExited?.();
          formik.resetForm();
        }
      }}
      {...other}
    >
      <form
        onSubmit={async (values, helpers) => {
          await formik.handleSubmit(values, helpers);
        }}
      >
        <Card>
          <CardHeader title={`Planilla de vehículo - ${lineState.placa}`} />
          <CardContent>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={3} xs={12}>
                <PropertyList>
                  <PropertyListItem
                    label="Chofer"
                    value={`${lineState.cardcode} - ${lineState.cardname}`}
                  />
                  <AllowedGuard
                    permission={GadminActions.GCC_DELIVERY_PAR_APOYOREP_UP}
                    component={
                      <PropertyListItem
                        label="# Auxiliares panilla"
                        value={`${lineState.nroauxpla}`}
                      />
                    }
                  >
                    <PropertyListItem label="# Auxiliares panilla">
                      <InputField
                        // label="Nro Aux Planilla"
                        error={Boolean(formik.touched.pagoxapoyo && formik.errors.nroauxpla)}
                        helperText={formik.touched.pagoxapoyo && formik.errors.pagoxapoyo}
                        name="nroauxpla"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.nroauxpla}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                      />
                    </PropertyListItem>
                  </AllowedGuard>
                  <AllowedGuard
                    permission={GadminActions.GCC_DELIVERY_PAR_APOYOREP_UP}
                    component={
                      <PropertyListItem label="Pago Aux Externo" value={`${lineState.nroauxpla}`} />
                    }
                  >
                    <PropertyListItem label="Pago Aux Externo">
                      <InputField
                        error={Boolean(formik.touched.pagoxapoyo && formik.errors.pagoxapoyo)}
                        helperText={formik.touched.pagoxapoyo && formik.errors.pagoxapoyo}
                        name="pagoxapoyo"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pagoxapoyo}
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                      />
                    </PropertyListItem>
                  </AllowedGuard>
                  <PropertyListItem label="Comentario">
                    <InputField
                      error={Boolean(formik.touched.comments && formik.errors.comments)}
                      helperText={formik.touched.comments && formik.errors.comments}
                      name="comments"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.comments}
                      multiline
                      rows={4}
                    />
                  </PropertyListItem>
                  <PropertyListItem
                    label="Clientes / Soles"
                    value={`${lineState.clientes} / ${lineState.soles}`}
                  />
                </PropertyList>
              </Grid>
              <Grid item md={8} xs={12}>
                {formik.errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{formik.errors.submit}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <AllowedGuard permission={GadminActions.GCC_DELIVERY_PAR_APOYOREP_UP}>
              <GoalButton color="primary" startIcon={<SaveIcon />} type="submit">
                Guardar
              </GoalButton>
            </AllowedGuard>
            <GoalButton
              startIcon={<CancelIcon />}
              onClick={async () => {
                onClose();
              }}
            >
              Cancelar
            </GoalButton>
          </CardActions>
        </Card>
      </form>
    </Dialog>
  );
};

AuxiliaryCurrentEdit.propTypes = {
  lineState: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  refreshLine: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired
  // setEditMode: PropTypes.func.isRequired
};
