import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import moment from 'moment';
// import { format } from 'date-fns';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Link,
  Checkbox
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import LoadingContent from '../../common/loading-content';
import { AllowedGuard } from '../../../hooks/use-allowed';
import { Refresh as RefreshIcon } from '../../../icons/refresh';
import GadminActions from '../../../contexts/gadmin-actions';

import { NumberColor } from '../../number-color';
import { RenderIf } from '../../render-if';

export const HRProgramedTable = ({
  requestData: documentState,
  onRefresh,
  onSelect,
  onSelectAll,
  selectedItems
}) => {
  const { data: documents } = documentState;
  const handleRefresh = () => {
    onRefresh?.();
  };

  if (documentState.isLoading || documentState.error) {
    return <LoadingContent loadingText={documentState.message} error={documentState.error} />;
  }

  return (
    <Card variant="outlined">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <CardHeader title="Hojas de reparto" sx={{ minWidth: 800 }} />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              alignItems: 'right',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <AllowedGuard permission={GadminActions.GCC_GPROC_MNGMT}>
              <Box sx={{ mb: 2 }}>
                <Button
                  color="primary"
                  size="medium"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefresh}
                  variant="contained"
                />
              </Box>
            </AllowedGuard>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={documents.length > 0 && selectedItems.length === documents.length}
                  disabled={documentState.isLoading}
                  indeterminate={
                    selectedItems.length > 0 && selectedItems.length < documents.length
                  }
                  onChange={onSelectAll}
                />
              </TableCell>

              {/* <TableCell>#</TableCell> */}
              <TableCell>HR</TableCell>
              <TableCell>F Programación</TableCell>
              <TableCell>F Despacho</TableCell>
              <TableCell>Zona</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Pedidos</TableCell>
              <TableCell>Clientes</TableCell>
              <TableCell>Soles</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentState.data?.map((item) => (
              <TableRow
                key={item.id}
                hover
                selected={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={!!selectedItems.find((selectedItem) => selectedItem === item.id)}
                    onChange={(event) => onSelect(event, item.id)}
                  />
                </TableCell>
                {/* <TableCell>{numeral(item.id).format('0')}</TableCell> */}
                <TableCell>{item.id}</TableCell>
                <TableCell>{moment(item.fecprog).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{moment(item.fecdsp).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{item.codzon}</TableCell>
                <TableCell>{item.plaveh}</TableCell>
                <TableCell>
                  <RenderIf
                    condition={item.pedidos === 0}
                    no={<NumberColor value={item.pedidos} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.pedidos}
                    </Link>
                  </RenderIf>
                </TableCell>

                <TableCell>
                  <RenderIf
                    condition={item.clientes === 0}
                    no={<NumberColor value={item.clientes} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.clientes}
                    </Link>
                  </RenderIf>
                </TableCell>
                <TableCell>
                  <RenderIf
                    condition={item.soles === 0}
                    no={<NumberColor value={item.soles} minColor="success.main" format="0" />}
                  >
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {item.soles}
                    </Link>
                  </RenderIf>
                </TableCell>
                <TableCell>{item.statusName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};

HRProgramedTable.propTypes = {
  requestData: PropTypes.object,
  onRefresh: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedItems: PropTypes.array
};
